<template>
  <input
    v-model="text"
    class="base-input"
    :style="{ '--width': width }"
    :type="type"
  >
</template>

<script>
export default {
  name: 'BaseInput',
  props: {
    value: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    width: {
      type: String,
      required: false,
      default: '450px'
    }
  },
  computed: {
    text: {
      get: vm => vm.value,
      set(v) {
        this.$emit('input', v);
      }
    }
  },
  methods: {
    NumbersOnly(evt) {
      if (evt.keyCode < 48 || evt.keyCode > 57) {
        evt.preventDefault();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.base-input {
  padding: 12px 28px 12px 16px;
  width: 100%;
  height: 62px;
  border: 1.5px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  outline-width: 0;
  margin-top: 10px;
}
</style>