import { createStore } from 'vuex'
import actions from './actions';

function capitalizarPrimeraLetra(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export default createStore({
  state: {
    currentStep: 0,
    loading: false,
    loadModal: false,
    user: {
      id: null,
      age: '',
      bio: '',
      gender: '',
      email: '',
      firstName: '',
      lastName: '',
      feetHeight: '',
      inchesHeight: '',
      zipCode: '',
      searchFor: '',
      photos: [],
      password: '',
      answers: [],
      jwtToken: '',
      refreshToken: '',
      userSelection:[],
      authkey: '',
      apiKey: '',
      prompts: [],
    },
    filter: {
      interested: '',
      zipCode: '',
      minAgeRange: '',
      maxAgeRange: '',
      maxDistance: '',
      minHeightRange: '',
      maxHeightRange: ''
    },
    photos: [],
    deletedPhotos: [],
    usersMatch:[],
    userLike:{},
    likesUser:[],
    feedBack: '',
    dataPrompt: {
      id: null,
      text: ''
    },
    listPrompt:[]
  },
  getters:{
    loadingStatus(state){
      return state.loading
    },
    loadingModal(state){
      return state.loadModal
    },
    user: state => state.user,
    feedBack: state => state.feedBack,
    getPrompt: state => state.dataPrompt,
    getListPrompt: state => state.listPrompt,
    //question
    currentStep: state => state.currentStep,
    //endquestion
    isAboutMeValid(state) {
      const {
        age,
        firstName,
        gender,
        feetHeight,
        inchesHeight,
        searchFor,
        zipCode
      } = state.user;
      return (
        age &&
        firstName &&
        gender &&
        feetHeight &&
        inchesHeight &&
        searchFor &&
        zipCode
      );
    },
    isAboutMeValidNew(state) {
      const {
        age,
        firstName,
        gender,
        feetHeight,
        inchesHeight,
        searchFor,
        zipCode
      } = state.user;

      let data = {
        age,
        firstName,
        gender,
        feetHeight,
        inchesHeight,
        searchFor,
        zipCode
      }

      let datafalse = [];
      let ff = '';
      Object.keys(data).map(item => {
        if(!data[item] || data[item] === ''){
          console.log(item, ' fff');
          if(item === 'gender'){
            item = 'I am a'
          }

          if(item === 'firstName'){
            item = 'My name is'
          }

          if(item === 'age'){
            item = 'My age is'
          }

          if(item === 'searchFor'){
            item = 'Seeking a'
          }

          if(item === 'zipCode'){
            item = 'My ZipCode is'
          }
        
          datafalse.push(item);
          ff += ` ${capitalizarPrimeraLetra(item)}, \n \n`
        }
      })

      if(datafalse.length === 7){
        return false;
      }

      if(datafalse.length > 0){
        return ff;
      }else{
        return true;
      }

    },
    isAboutMeValidNewTry(state) {
      const {
        age,
        gender,
        searchFor,
      } = state.user;

      let data = {
        age,
        gender,
        searchFor,
      }

      let datafalse = [];
      let ff = '';
      Object.keys(data).map(item => {
        console.log(data[item]);
        if(!data[item] || data[item] === ''){
          console.log(item, ' fff');
          if(item === 'gender'){
            item = 'I am a'
          }

          if(item === 'firstName'){
            item = 'My name is'
          }

          if(item === 'age'){
            item = 'My age is'
          }

          if(item === 'searchFor'){
            item = 'Seeking a'
          }

          if(item === 'zipCode'){
            item = 'My ZipCode is'
          }
        
          datafalse.push(item);
          ff += ` ${capitalizarPrimeraLetra(item)}, \n \n`
        }
      })

      if(datafalse.length === 7){
        return false;
      }

      if(datafalse.length > 0){
        return ff;
      }else{
        return true;
      }
    },
    //endquestionbio
    bio: state => state.user.bio,
    //endquestionphoto
    photos: state => state.photos,
    //finishacount
    email: state => state.user.email,
    //usersMatch
    usersMatch: state => state.usersMatch,
    //oneusermatchtoshow
    userMatch: state => state.usersMatch[0],
    //editprofilephotos
    deletedPhotos: state => state.deletedPhotos,
    //settings
    filter: state => state.filter,
    //viewuserlikes
    userLike: state => state.userLike,
    usersSelections(state) {
      return state.user.userSelection.filter((us) => us.isLiked == true)
    }, 
    likesUser: state => state.likesUser
  },
  mutations:{
    loadingStatus(state, status){
      state.loading = status
    },
    loadingModal(state, status){
      state.loadModal = status
    },
    setUser(state, payload) {
      state.user = payload;
      state.filter = payload.settings;
      if(payload.prompts){
        state.listPrompt = state.user.prompts;
      }
    },
    //logout
    clearUser(state) {
      state.user = {
        id: null,
        age: '',
        bio: '',
        gender: '',
        email: '',
        firstName: '',
        lastName: '',
        feetHeight: '',
        inchesHeight: '',
        zipCode: '',
        searchFor: '',
        photos: [],
        password: '',
        answers: [],
        prompts: [],
      };
    },
    clearFilters(state) {
      state.filter = {
        interested: '',
        zipCode: '',
        minAgeRange: '',
        maxAgeRange: '',
        maxDistance: '',
        minHeightRange: '',
        maxHeightRange: ''
      };
    },
    clearPrompt(state){
      state.dataPrompt = {
        id: null,
        text: ''
      };
      state.listPrompt = [];
    },
    clearPhotos(state) {
      state.photos = [];
    },
    //singup
    setCurrentStep(state, payload) {
      state.currentStep = payload;
    },
    //answer question
    setAnswer(state, payload) {
      let answer = state.user.answers.find(
        ({ question_id }) => question_id === payload.question_id
      );
      if (answer) {
        answer.answer_number = payload.answer_number;
      } else {
        state.user.answers.push(payload);
      }
    },
    //questionlist
    setSearchFor(state, payload) {
      state.user.searchFor = payload;
    },
    setGender(state, payload) {
      state.user.gender = payload;
    },
    setFeedBack(state, payload) {
      state.feedBack = payload;
    },
    setPromptText(state, payload) {
      state.dataPrompt.text = payload;
    },
    setPromptId(state, payload) {
      state.dataPrompt.id = payload;
    },
    addPromptsToUser(state, payload){
      state.user.prompts = payload;
      state.listPrompt = [];
    },
    setPromptToList(state, payload) {
      const itemIndex = state.listPrompt.findIndex(x => x.id === payload.id);
      if(itemIndex >= 0){
        state.listPrompt[itemIndex].text = payload.text;
        return;
      }
      state.listPrompt.push({...payload});
    },
    deletePromptToList(state, payload) {
      const itemIndex = state.listPrompt.filter(x => x.id !== payload);
      state.listPrompt = itemIndex;
    },
    setFirstName(state, payload) {
      state.user.firstName = payload;
    },
    setLastName(state, payload) {
      state.user.lastName = payload;
    },
    setAge(state, payload) {
      state.user.age = payload;
    },
    setHeightFeet(state, payload) {
      state.user.feetHeight = payload;
    },
    setHeightInches(state, payload) {
      state.user.inchesHeight = payload;
    },
    setZipCode(state, payload) {
      state.user.zipCode = payload;
    },
    //endcuestionbio
    setBio(state, payload) {
      state.user.bio = payload;
    },
    //endquestionphoto
    addPhoto(state, payload) {
      state.photos = payload;
    },
    //finishacount
    setEmail(state, payload) {
      state.user.email = payload;
    },
    setUserPassword(state, payload) {
      state.user.password = payload;
    },
    //register photo
    addPhotoName(state, payload) {
      state.user.photos.push(payload);
    },
    deletePhotoName(state) {
      state.user.photos = [];
    },
    //matchtListusers
    setUsersMatch(state,payload){
      state.usersMatch = payload.filter(element => element.photos.length >= 0);
      console.log(state.usersMatch);
    },
    deleteUserMatch(state){
      state.usersMatch = state.usersMatch.filter((element, index) => index > 0);
    },
    //editprofile
    addDeletedPhoto(state, payload) {
      state.deletedPhotos = payload;
    },
    updatePhotoName(state, payload) {
      state.user.photos[payload.index] = payload.key;
    },
    //TODO settings 
    setInterested(state, payload) {
      state.filter = {
        ...state.filter,
        interested: payload
      };
    },
    setFilterZipCode(state, payload) {
      state.filter = {
        ...state.filter,
        zipCode: payload
      };
    },
    setAgeRange(state, payload) {
      state.filter = {
        ...state.filter,
        minAgeRange: payload.min,
        maxAgeRange: payload.max
      };
    },
    setDistance(state, payload) {
      state.filter = {
        ...state.filter,
        maxDistance: payload
      };
    },
    setHeightRange(state, payload) {
      state.filter = {
        ...state.filter,
        minHeightRange: payload.min,
        maxHeightRange: payload.max
      };
    },
    //user to like
    setUserLike(state, payload) {
      state.userLike = payload;
    },
    deleteUserLike(state) {
      state.userLike = null;
    },
    //likesListusers
    setUserLikes(state,payload){
      state.likesUser = payload
    },
    deleteUserLikes(state){
      state.likesUser = [];
    },
  },
  actions 
})
