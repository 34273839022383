<template>
  <div>
    <component :is="componentName" />
  
    <v-dialog
      v-model="dialog"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title
          class="thbtn"
        >
          This site only works if you give thoughtful answers
        </v-card-title>
        <v-card-text />
        <br>
        <v-card-actions style="display: flex; flex-direction: row; justify-content: center;">
          <button
            class="base_button"
            @click="aceptPage()"
          >
            Ok
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  Questions,
  AboutMe,
  Bio,
  Photos,
  FinishAccount,
  Success
} from '@/components/SignUp';
import { questions } from '@/data/questions';

export default {
  name: 'Signup',
  components: {
    Questions,
    AboutMe,
    Bio,
    Photos,
    FinishAccount,
    Success
  },
  data(){
    return{ 
      dialog: true,
    }
  },
  computed: {
    ...mapGetters(['currentStep']),
    componentName() {
      let aryComponents = [
        'Questions',
        'AboutMe',
        'FinishAccount',
      ];
      let curType = this.currentStep - questions.length + 1;
      if (curType < 0) curType = 0;
      return aryComponents[curType];
    }
  },
  created() {
    this.clearPrompt();
  },
  methods: {
    ...mapMutations(['clearPrompt']),
    aceptPage(){
      this.dialog = false;
    }
  }
};
</script>
