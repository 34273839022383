<template>
  <SignUpStep
    title="Forgot Password"
    class="login"
  >
    <div class="login__form">
      <BaseError
        v-if="error !== null"
        :message="error"
      />
      <v-alert
        v-if="emailSend"
        type="success"
        dark
        dense
        icon="mdi-star"
        prominent
        shaped
      >
        An email has been sent to reset your password.
      </v-alert>
      <BaseLabel
        label="Email"
        class="login__label"
      />
      <BaseInput
        v-model="email"
        :value="email"
        placeholder="Enter your email address"
        class="login__input"
      />

      <BaseButton
        class="login__submit"
        text="Send email"
        @my-event="submit"
      />
    </div>
    <Loader v-if="loadStatus" />
  </SignUpStep>
</template>
<script>
import {
  BaseError,
  BaseButton,
  BaseInput,
  BaseLabel,
  Loader
} from '@/components/Element';
import SignUpStep from '@/components/Layout/SignUpStep';
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',
  components: {
    BaseError,
    BaseButton,
    BaseInput,
    BaseLabel,
    SignUpStep,
    Loader
  },
  data(){
    return {
      email: '',
      password: '',
      error: null,
      loadStatus: false,
      emailSend: false
    };
  },
  methods: {
    ...mapActions(['ForgotPassword']),
    async submit(){
      this.emailSend = false;
      this.error = null;
      if (!this.email) {
        this.error = 'Email Address is required.';
        return;
      }

      if (!/.+@.+\..+/.test(this.email)) {
        this.error = 'Email Address is not valid.';
        return;
      }

      this.loadStatus = true;
      const res = await this.ForgotPassword({
        email: this.email,
      });
      if (res.valid) {
        this.loadStatus = false;
        this.emailSend = true;
        this.email = '';
      } else {
        this.emailSend = false;
        this.loadStatus = false;
        if(res.ms){
          this.error = 'Reset Password, Try again.  ' + res.ms;
        }
      }

    }
  }
}
</script> 

<style lang="scss" scoped>
.login {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    width: 450px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: calc(100% - 40px);
      padding-top: 30px;
    }
  }

  &__label {
    margin-top: 30px;

    &:not(:first-child) {
      margin-top: 60px;
    }

    @media (max-width: 500px) {
      margin: 0;
      font-size: 24px;
      text-align: left;
    }
  }

  &__submit {
    margin-top: 40px;
  }

  .info-message {
    margin-left: 50px;
  }
}
</style>