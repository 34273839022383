<template>
  <div class="prompts">
    <Load v-if="stateLoad" />
    <v-toolbar
      dark
      color="primary"
    > 
      <v-btn
        icon
        dark
        @click="closePrompts()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title class="text-wrap">
        Try 3 Prompts
      </v-toolbar-title>
    </v-toolbar>
    <v-list
      rounded
      style="background-color: rgb(250, 250, 250); margin-bottom: 50px;"
    >
      <v-list-item
        v-for="item in currentPrompts"
        :key="item.id"
        style="background-color: white; margin-bottom: 3%; "
      >
        <div
          style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;"
        >
          <div
            v-if="item.valid"
            style="cursor: pointer; margin-right: 10px;"
            @click="deletePromptModal(item.id)"
          >
            <v-icon
              :color="'red'"
              icon="mdi-trash-can-outline"
            />
          </div>

          <div
            class="text"
            style="cursor: pointer; width: 100%; display: flex; flex-direction: row; justify-content: space-between;"
            @click="openDialog(item)"
          >
            <div
              style="cursor: pointer; "
            >
              <v-list-item-title
                class="text-wrap"
                v-text="item.title"
              />
            </div>

            <div
              style="cursor: pointer; width: fit-content;"
            >
              <v-icon
                :color="'grey'"
                icon="mdi-chevron-right"
              />
            </div>
          </div>
        </div>
      </v-list-item>
      <v-divider />
    </v-list>
    <v-footer
      color="rgb(250, 250, 250)"
      class="foot"
    >
      <v-card
        color="rgb(250, 250, 250)"
        width="100%"
        class="d-flex flex-row"
      >
        <v-card-text
          class="d-flex justify-start"
          style="color: black; align-items: center;"
        >
          <div
            class="text--primary"
            style="font-size: 18px;  font-weight: bold;"
          >
            {{ countPrompt }} Prompts
          </div>
        </v-card-text>
        <v-spacer />
        <v-card-text class="d-flex justify-end">
          <v-btn
            class="mx-4 white--text"
            dark
            color="primary"
            @click="saveAllPrompt()"
          >
            Save
          </v-btn>
        </v-card-text>
      </v-card>
    </v-footer>
    <v-dialog
      v-model="modalPrompt"
      scrollable
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-wrap"
          style="font-size: 18px; overflow-wrap: break-word !important; "
        >
          {{ dataDialog.titleItem }}
        </v-card-title>
        <v-divider />
        <v-card-text style="margin: 0px; padding: 0px; width: 100%;">
          <div
            class="base-text-area"
            style="padding-left: 5%; padding-right: 5%;"
          >
            <textarea
              v-model="text"
              style="height: 150px;"
            />
            <div
              v-if="limit !== null"
              class="base-text-area__limit"
            >
              {{ text.length }}/{{ limit }}
            </div>
          </div>
        </v-card-text>
        
        <v-divider />
        <v-card-actions class="justify-end">
          <v-btn
            color=" darken-1"
            text
            @click="closeDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="savePrompt()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalDelete"
      width="600"
      style="border-radius: 50px"
    >
      <v-card style="display:flex; flex-direction: column">
        <v-card-title
          class="th"
          style="hyphens: auto; overflow-wrap: break-word;"
        >
          Are you sure you want to remove?
        </v-card-title>
        <v-icon
          right
          large
          color="green"
          style="font-size:40px; align-self: center"
        >
          mdi-delete-empty-outline
        </v-icon>
        <v-card-actions class="d-flex justify-center"> 
          <v-btn
            class="th"
            color="danger"
            text
            @click="closeDeletePromptModal()"
          >
            Cancel
          </v-btn>
          <v-btn
            class="th"
            color="primary"
            text
            @click="deletePrompt()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalError"
      width="250px"
      style="border-radius: 50px; overflow-x: hidden !important;"
    >
      <v-card>
        <div style="text-align: center;">
          <v-icon
            large
            color="red"
            style="font-size:80px"
          >
            mdi-alert-circle-outline
          </v-icon>
          <v-card-text>
            {{ modalErrorText }}
          </v-card-text>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="black"
            text
            @click="setModal()"
          >
            Ok
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { Load } from '@/components/Element';
import { prompts } from '@/data/prompts';

export default {
  name: 'TryPrompts',
  components: {
    Load
  },
  data() {
    return {
      stateLoad: false,
      dialogFeedBack: false,
      dataDialog:{
        idItem: null,
        titleItem: null
      },
      modalPrompt: false,
      modalDelete: false,
      idDeletePrompt: null,
      modalError: false,
      modalErrorText: '',
      limit:250,
      text:'',
    };
  },
  computed: {
    ...mapGetters(['user', 'filter', 'getPrompt', 'getListPrompt']),
    countPrompt(){
      return this.getListPrompt.length;
    },
    currentPrompts() {
      let newArray = [];
      // eslint-disable-next-line no-unused-vars
      prompts.forEach((element, index) => {
        const id = this.getListPrompt.findIndex(x => x.id === element.id);
        if(id >= 0){
          newArray.push({
            id: element.id,
            title: element.title,
            valid: true,
          })
        }else{
          newArray.push({
            id: element.id,
            title: element.title,
            valid: false,
          })
        }
      });
      return newArray;
    },
    maxAge() {
      let array = [];
      for (let i = 18; i <= 90; i++) {
        array.push(i);
      }
      return array;
    },
    minAge() {
      let array = [];
      for (let i = 18; i <= 90; i++) {
        array.push(i);
      }
      return array;
    },
    labelAgeRange() {
      return this.minAgeRange && this.maxAgeRange
        ? this.minAgeRange + ' - ' + this.maxAgeRange
        : '';
    },
    labelDistance() {
      return this.maxDistance > 0 ? this.maxDistance + ' mile' : 'Any';
    },
    labelHeight() {
      return (this.minHeightRange != '0') ? this.minHeightRange +' to ' + this.maxHeightRange : 'Any'
    },
    setHe(){
      let array = [];
      for (let index = 4; index <= 7; index++){
        for(let j = 0; j < 12; j++){
          if(index == 7 && j > 0) break;
          array.push(index+ '’ '+ j+ '”');
        }  
      }
      return array;
    }
  },
  watch: {
    text(val) {
      if(val && val.length){
        if (val.length > this.limit) {
          this.text = val.substr(0, this.limit);
        }
      }
      
    }
  },
  created() {
    this.interested = this.filter.interested;
    this.zipCode = this.filter.zipCode;
    this.minAgeRange = this.filter.minAgeRange;
    this.maxAgeRange = this.filter.maxAgeRange;
    this.maxDistance = this.filter.maxDistance;
    this.minHeightRange = this.filter.minHeightRange;
    this.maxHeightRange = this.filter.maxHeightRange;
    this.scrollTop();
  },
  methods: {
    ...mapActions(['saveSettings', 'logout', 'deleteAccount', 'sendFeedBackUser','addDataPromptUser','sendDataPromptUser']),
    ...mapMutations([
      'setInterested',
      'setFilterZipCode',
      'setAgeRange',
      'setDistance',
      'setHeightRange', 
      'setFeedBack',
      'setPromptText',
      'setPromptId',
      'deletePromptToList'
    ]),
    openDialog(item){
      this.dataDialog = {
        idItem: item.id,
        titleItem: item.title
      }
      this.setPromptText('');
      this.text = '';
      this.setPromptId(item.id);
      const itemIndex = this.getListPrompt.findIndex(x => x.id === item.id);
      if(itemIndex >= 0){
        const dataOfList = this.getListPrompt[itemIndex];
        this.setPromptText(dataOfList.text);
        this.text = dataOfList.text;
      }
      this.modalPrompt = true;
    },
    closeDialog(){
      this.dataDialog = {
        idItem: null,
        titleItem: null
      }
      this.setPromptText('');
      this.text = '';
      this.setPromptId(null);
      this.modalPrompt = false;
    },
    async savePrompt(){
      console.log(this.text);
      if(this.getPrompt.id == null || this.text == ''){
        this.modalErrorText = 'Complete';
        this.modalError = true;
        return;
      }
      this.setPromptText(this.text);
      try {
        const dataResponse = await this.addDataPromptUser();
        if(dataResponse){
          this.closeDialog();
        }else { 
          this.modalErrorText = 'Error';
          this.modalError = true;
        }
      } catch (error) {
        console.log(error);
        this.modalErrorText = 'Error';
        this.modalError = true;
      }
    },
    async deletePromptModal(id){
      this.idDeletePrompt = id;
      this.modalDelete = true;
    },
    // eslint-disable-next-line no-unused-vars
    async closeDeletePromptModal(id){
      this.idDeletePrompt = null;
      this.modalDelete = false;
    },
    async deletePrompt(){
      if(this.idDeletePrompt == null){
        this.modalErrorText = 'Error';
        this.modalError = true;
        return;
      }
      try {
        this.deletePromptToList(this.idDeletePrompt);
        this.closeDeletePromptModal();
      } catch (error) {
        console.log(error);
        this.modalErrorText = 'Error';
        this.modalError = true;
      }
    },
    async saveAllPrompt(){
     if(this.getListPrompt.length < 3){
      this.modalErrorText = '3 Prompt Minimum.';
      this.modalError = true;
      return;
     }

     try {
      const data = await this.sendDataPromptUser();
      if(data){
        this.$router.go(-1);
      }else { 
        this.modalErrorText = 'Error';
        this.modalError = true;
      }
     } catch (error) {
      console.log(error);
      this.modalErrorText = 'Error';
      this.modalError = true;
     }
    },
    closePrompts(){
      this.$router.go(-1);
    },
    setModal(){
      this.modalError = false;
      this.modalErrorText = '';
    },
    async scrollTop(){
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
  }
};
</script>

<style lang="scss" scoped>

.base-text-area {
  width: 100%;
  @media (max-width: 500px) {
    width: 100%;
  }
  textarea {
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    border: 1.5px solid #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__limit {
    text-align: right;
    margin-top: 7px;
  }
}
.prompts{
  @media (min-width: 600px) {
    margin-right: 20%;
    margin-left: 20%;
  }

  .tool-bar-class{
    background: red;
    margin: 0px;
  }

  .foot{
    @media (min-width: 600px) {
      margin-right: 20%;
      margin-left: 20%;
    }
  }


  .txtarea {

    @media (max-width: 500px) {
      margin-bottom: 0;
      textarea {
        height: 100px;
      }
    }
  }
}
</style>