<template>
  <div>
    <div class="hed">
      <a
        v-if="ttHead"
        href="https://tryfilters.com/"
        class="ttHead"
      >Filters</a>

      <v-btn
        v-if="back"
        color="primary"
        icon="mdi-chevron-left"
        x-small
        dark
        class="btnHead"
        @click="backStep()"
      />
      <BaseHeader
        :title="title"
        :show-score="showScore"
      />
    </div>
    
    <slot />
  </div>
</template>

<script>
  import { BaseHeader } from '@/components/Element';
import { mapMutations } from 'vuex';

  export default {
    name: 'SignupDescription',
    components: {
      BaseHeader,
    },
    props: {
      title: {
        type: String,
        required: false,
        default: '',
      },
      back: {
        type: Boolean,
        required: false,
        default: false,
      },
      ttHead: {
        type: Boolean,
        required: false,
        default: false,
      },
      step: {
        type: Number,
        required: false,
        default: 0,
      },
      showScore: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    methods: {
      ...mapMutations(['setCurrentStep']),
      backStep() {
        this.setCurrentStep(this.step);
      }
    }
  };
</script>

<style lang="scss" scoped>
    .hed{
      //background-color: blue !important;
      display: flex; 
      flex-direction: row;
      justify-content: center;
      align-items: center;
      @media (max-width: 600px) {
        flex-direction: row;
      }

      .ttHead{
        align-self: flex-end;
        margin-left: 50px;
        color: #009933; 
        font-size: 30px;
        font-weight: 700;
        line-height: 35px;
        letter-spacing: 0.07em;
        cursor: pointer;
        text-decoration: none;
        margin-top: 20px; 
        @media (max-width: 500px) {
          margin-left: 20px;
          box-shadow: none;
          font-size: 20px;
          line-height: 30px;
        }
      }


      .btnHead{
        margin-top: 20px; 
        
        @media (max-width: 600px) {
          margin-top: 20px; 
        }
      }
    }
</style>
