<template>
  <div class="loading-animation">
    <div class="centered">
      <div class="blob-1" />
      <div class="blob-2" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Loader',
  };
</script>

<style scoped>
  .loading-animation {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    background-color: rgba(160, 160, 160, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    animation: .35s ease-in-out 0s fadeAppearing;
  }

  .centered {
    position: absolute;
    width: 400px;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .blob-1, .blob-2 {
    width: 40px;
    height: 40px;
    position: absolute;
    background: #7EA45E;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  .blob-1 {
    left: 20%;
    animation: osc-l 2.5s ease infinite;
  }

  .blob-2 {
    left: 80%;
    animation: osc-r 2.5s ease infinite;
    background: #4C5B7A;
  }

  @keyframes osc-l {
    0% {left: 20%;}
    50% {left: 50%;}
    100% {left: 20%;}
  }

  @keyframes osc-r {
    0% {left: 80%;}
    50% {left: 50%;}
    100% {left: 80%;}
  }
</style>
