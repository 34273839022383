<template>
  <div class="info-message">
    <img
      v-if="showIcon"
      class="info-message__icon"
      src="../../assets/info.svg"
      alt="..."
    >
    <p class="info-message__note">
      {{ message }}
    </p>
  </div>
</template>

<script>
  export default {
    name: 'BaseInfo',
    props: {
      showIcon: {
        type: Boolean,
        required: false,
        default: true,
      },
      message: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .info-message {
    display: flex;
    align-items: center;
    width: 450px;
    margin-top: 10px;

    @media (max-width: 500px) {
      width: 100%;
    }

    &__icon {
      margin-right: 10px;
    }

    &__note {
      font-size: 16px;
      color: #000;
      margin: 0;
    }
  }
</style>