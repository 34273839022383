export const questions = [
  {
    id: 1,
    title: 'In large groups I tend to be ',
    options: [
      'Really Shy',
      'Kinda Shy',
      'Neutral',
      'Kinda Outgoing',
      'Really Outgoing'
    ]
  },
  {
    id: 2,
    title: 'Are you self motivated or do you need a push to get started',
    options: [
      'Really Self Motivated',
      'Kinda Self Motivated',
      'Neutral',
      'Kinda Need A Push',
      'Really Need A Push'
    ]
  },
  {
    id: 3,
    title: 'Do you go with the flow or chart your own path',
    options: [
      'Really Go With The Flow',
      'Kinda Go With The Flow',
      'Neutral',
      'Kinda Chart My Own Path',
      'Really Chart My Own Path'
    ]
  },
  {
    id: 4,
    title: 'Do you watch what you say or say what’s on your mind',
    options: [
      'Really Watch What I Say',
      'Kinda Watch What I Say',
      'Neutral',
      'Kinda Say What’s On My Mind',
      'Really Say What’s On My Mind'
    ]
  },
  {
    id: 5,
    title: 'Is it more important to achieve goals or accept yourself',
    options: [
      'Really Achieve Goals',
      'Kinda Achieve Goals',
      'Neutral',
      'Kinda Accept Yourself',
      'Really Accept Yourself'
    ]
  },
  {
    id: 6,
    title: 'Do you stick to what you know or try new things',
    options: [
      'Really Stick To What I Know',
      'Kinda Stick To What I Know',
      'Neutral',
      'Kinda Like To Try New Things',
      'Really Like To Try New Things'
    ]
  },
  {
    id: 7,
    title: 'In stressful situations are you more likely to worry or stay calm',
    options: [
      'Really Worry',
      'Kinda Worry',
      'Neutral',
      'Kinda Stay Calm',
      'Really Stay Calm'
    ]
  },
  {
    id: 8,
    title: 'Should government focus on providing for the needy or protecting individual rights',
    options: [
      'Really Focus On Needy',
      'Kinda Focus On Needy',
      'Neutral',
      'Kinda Focus On Individual Rights',
      'Really Focus On Individual Rights'
    ]
  },
  {
    id: 9,
    title: 'Do you prefer to work on a team or work alone',
    options: [
      'Really Prefer A Team',
      'Kinda Prefer A Team',
      'Neutral',
      'Kinda Prefer Alone',
      'Really Prefer Alone'
    ]
  },
  {
    id: 10,
    title: 'High School Grades',
    options: [
      'Took advanced classes and got A’s and B’s',
      'Took normal classes and got A’s and B’s',
      'Got B’s and C’s',
      'Got C’s and D’s',
      'School is not for me'
    ]
  }
]