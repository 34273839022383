/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import axios from '@/plugins/axios';
import router from '../router/index'

const uploadFile = async (params) => {
  var dataFormImages = new FormData();
  dataFormImages.append('file', params);
  return axios.post('/users/upphoto', dataFormImages, {
    headers: {
      'Content-Type': 'multipart/form-data',
    }
  }).then(res => {
    return {
      valid: true,
      data: res.data,
    };
  }).catch(err => {
    console.log(err.response);
    let txt = 'server error';
    if(err.data){
      txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
    }
    if(err.data === ''){
      txt = err.statusText
    }
    return {
      valid: false,
      data: txt,
    };
  })
};

const deleteFile = async (params) => {
  var s = {}
  return axios.post(`/users/deletePhoto?file=${params}`, s).then(res => {
    return {
      valid: true,
      data: res.data,
    };
  }).catch(err => {
    let txt = 'server error';
    if(err.data){
      txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
    }
    return {
      valid: false,
      data: txt,
    };
  })
};

const updateUserPhoto = ({ uid, url, api }) => {
  axios.put('https://207577c306fbca5b.api-us.cometchat.io/v3/users/'+uid, JSON.stringify(
    {
      avatar: url
    }
  ), {
    headers: {Accept: 'application/json', apiKey: api, 'Content-Type': 'application/json'},
  }).then(response => {
  }).catch(error => {
    console.log(error);
  });
  return;
}

const addFriend = async ({ f, u, api}) => {
  axios.post('https://207577c306fbca5b.api-us.cometchat.io/v3/users/'+u+'/friends', JSON.stringify({accepted: f}), {
    headers: {Accept: 'application/json', apiKey: api, 'Content-Type': 'application/json'},
  }).then(response => {
  }).catch(error => {
    console.log(error);
  });
  return;
};

const deleteUser = async ({id, api}) => {
  axios.delete('https://207577c306fbca5b.api-us.cometchat.io/v3/users/'+id,{
    headers: {Accept: 'application/json', apiKey: api, 'Content-Type': 'application/json'},
  }).then(res => {
  }).catch(err => {
    console.log(err);
  })
};

export default {
  async register({ commit, getters, dispatch }) {
    for await (let i of  getters.photos){
      const photo = i;
      try {
        const res = await uploadFile(photo);
        if(res.valid){
          commit('addPhotoName', res.data);
        }else{
          return {
            valid: false,
            message: res.data,
          };
        }
      } catch (error) {
        console.log(error);
        return {
          valid: false,
          message: error,
        };
      }
    }
    return await dispatch('sendRegisterRequest');
  },
  async sendRegisterRequest({ commit, getters }) {
    const {
      age,
      bio,
      gender,
      email,
      firstName,
      lastName,
      feetHeight,
      inchesHeight,
      zipCode,
      searchFor,
      password,
      answers,
      prompts
    } = getters.user;
    const fh = parseFloat(feetHeight);
    const ih = parseFloat(inchesHeight);
    return axios.post('/users/register', {
        age,
        bio,
        gender,
        email,
        firstName,
        lastName,
        feetHeight:fh,
        inchesHeight:ih,
        zipCode,
        searchFor,
        photos: [],
        password,
        answers,
        prompts
      })
      .then(res => {
        commit('setUser', res.data);
        localStorage.setItem('token', res.data.refreshToken);
        return {
          valid: true,
        }
      })
      .catch(err => {
        console.log('error', err);
        let txt = 'Internal Error: ' + err;
        if(err.data){
          txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
        }
        commit('deletePhotoName');
        return {
          valid: false,
          message: txt,
        }
      });
  },
  async sendRegisterRequestTry({ commit, getters }) {
    commit('loadingStatus', true)
    const {
      age,
      bio,
      gender,
      email,
      firstName,
      lastName,
      searchFor,
      password,
    } = getters.user;
    const fh = parseFloat(4);
    const ih = parseFloat(0);
    return axios.post('/users/try-register', {
        age,
        bio,
        gender,
        email,
        firstName,
        lastName,
        feetHeight:fh,
        inchesHeight:ih,
        zipCode: 11111,
        searchFor,
        photos: [],
        password,
        answers: [
          {
            'Question_id': 1,
            'Answer_number': 2
          },
          {
            'Question_id': 2,
            'Answer_number': 2
          },
          {
            'Question_id': 3,
            'Answer_number': 2
          },
          {
            'Question_id': 4,
            'Answer_number': 2
          },
          {
            'Question_id': 5,
            'Answer_number': 2
          },
          {
            'Question_id': 6,
            'Answer_number': 2
          },
          {
            'Question_id': 7,
            'Answer_number': 2
          },
          {
            'Question_id': 8,
            'Answer_number': 2
          },
          {
            'Question_id': 9,
            'Answer_number': 2
          },
          {
            'Question_id': 10,
            'Answer_number': 2
          }
        ],
        prompts:[]
      })
      .then(res => {
        commit('setUser', res.data);
        console.log(res.data);
        localStorage.setItem('token', res.data.refreshToken);
        commit('loadingStatus', false)
        commit('clearFilters');
        return {
          valid: true,
        }
      })
      .catch(err => {
        console.log('error', err);
        let txt = 'Internal Error: ' + err;
        if(err.data){
          txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
        }
        commit('deletePhotoName');
        commit('loadingStatus', false);
        return {
          valid: false,
          message: txt,
        }
      });
  },
  login({ commit }, payload) {
    return axios
      .post('/users/login', payload)
      .then(res => {
        commit('setUser', res.data);
        localStorage.setItem('token', res.data.refreshToken);
        return {
          valid:true
        };
      })
      .catch(err => {
        commit('loadingStatus', false)
        let txt = 'server error';
        if(err.data){
          txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
        }
        return {
          valid: false,
          ms: txt,
        };
      });
  },
  ForgotPassword({ commit }, payload) {
    return axios
      .post('/users/forgotPassword', payload)
      .then(res => {
        return {
          valid:true
        };
      })
      .catch(err => {
        let txt = 'server error';
        if(err.data){
          txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
        }
        commit('loadingStatus', false)
        return {
          valid: false,
          ms: txt
        };
      });
  },
  ValidateEmailReset({ commit }, payload) {
    return axios
      .post('/users/validateEmailReset', payload)
      .then(res => {
        return {
          valid:true,
          data: res.data,
        };
      })
      .catch(err => {
        let txt = 'server error';
        if(err.data){
          txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
        }
        commit('loadingStatus', false)
        return {
          valid: false,
          ms: txt,
        };
      });
  },
  ResetPassword({ commit }, payload) {
    return axios
      .post('/users/resetPassword', payload)
      .then(res => {
        return {
          valid:true,
          data: res.data,
        };
      })
      .catch(err => {
        commit('loadingStatus', false)
        let txt = 'server error';
        if(err.data){
          txt = (err && err.data && err.data.message) ? err.data.message : 'Server error';
        }
        return {
          valid: false,
          ms: txt,
        };
      });
  },
  refreshToken({ commit }) {
    const token = localStorage.getItem('token');
    console.log('s', token);
    if (!token || token === '') return false;
    return axios
      .post('/users/refreshToken', { token })
      .then(res => {
        commit('setUser', res.data);
        localStorage.setItem('token', res.data.refreshToken);
        return true;
      })
      .catch(err => {
        return false;
      });
  },
  async logout({ commit }) {
    commit('clearUser');
    commit('clearFilters');
    commit('clearPhotos'); 
    commit('clearPrompt');
    commit('setUsersMatch', []);
    localStorage.removeItem('token');
    return true;
  },
  async logoutTry({ commit }) {
    return axios
    .post('/Users/logout-temporal', {})
    .then(res => {
      if(res.data){
        const data = res.data;
        commit('clearUser');
        commit('clearFilters');
        commit('clearPhotos'); 
        commit('clearPrompt');
        commit('setUsersMatch', []);
        localStorage.removeItem('token');
        return true;
      }else{
        return false;
      }
    })
    .catch(err => {
      console.log(err);
      commit('loadingStatus', false)
      return false;
    });  
    
  },
  async sendFeedBackUser({commit, getters}){
    commit('loadingStatus', false);
    return axios
    .post('/Users/saveFeedBack',{
      feedBack: getters.feedBack,
    })
    .then(res => {
      if(res.data){
        const data = res.data;
        if(data === true){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    })
    .catch(err => {
      console.log(err);
      commit('loadingStatus', false)
      return false;
    });  
  },
  async addDataPromptUser({commit, getters}){
    commit('loadingStatus', false);
    try {
      commit('setPromptToList',getters.getPrompt)
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  async sendDataPromptUser({commit, getters}){
    commit('loadingStatus', false);
    return axios
    .post('/Users/saveUserPrompt',{
      userPrompts: getters.getListPrompt,
    })
    .then(res => {
      if(res.data){
        const data = res.data;
        if(data === true){
          return true;
        }else{
          return false;
        }
      }else{
        return false;
      }
    })
    .catch(err => {
      console.log(err);
      commit('loadingStatus', false)
      return false;
    });  
  },
  async deleteEmailUser({commit} , payload){
    return axios
    .get('/Users/get/deleteEmail/'+payload)
    .then(res => {
      return true;
    })
    .catch(err => {
      commit('loadingStatus', false)
      return false;
    });  
  },
  async getListUserMatch({commit}){
    commit('loadingStatus', true)
    return axios
    .get('/users/getMatchedUsers')
    .then(res => {
      commit('setUsersMatch', res.data);
      commit('loadingStatus', false)
      return true;
    })
    .catch(err => {
      commit('loadingStatus', false)
      return false;
    });  
  },
  async getListUserMatchTry({commit}){
    commit('loadingStatus', true)
    return axios
    .get('/users/getMatchedUsersTry')
    .then(res => {
      commit('setUsersMatch', res.data);
      commit('loadingStatus', false)
      return true;
    })
    .catch(err => {
      commit('loadingStatus', false)
      return false;
    });  
  },
  async getListUserTry({commit, getters}){
    commit('loadingStatus', true)
    const {
      age,
      bio,
      gender,
      email,
      firstName,
      lastName,
      feetHeight,
      inchesHeight,
      zipCode,
      searchFor,
      photos,
      password,
      answers,
      prompts
    } = getters.user;
    const fh = parseFloat(feetHeight);
    const ih = parseFloat(inchesHeight);
    return axios
    .post('/users/getUsersTry',{
      age,
      bio,
      gender,
      email,
      firstName,
      lastName,
      feetHeight:fh,
      inchesHeight:ih,
      zipCode,
      searchFor,
      photos: [],
      password,
      answers,
      prompts
    })
    .then(res => {
      commit('setUsersMatch', res.data);
      commit('loadingStatus', false)
      return true;
    })
    .catch(err => {
      commit('loadingStatus', false)
      return false;
    });  
  },
  async deleteUserMatch({commit}){
    commit('deleteUserMatch')
  },
  async setUserSelected({commit, getters}, payload ){
    const { apiKey } = getters.user;
    commit('loadingStatus', true)
    return axios
    .post('/Users/saveUserSelection',{
      userId: payload.userId,
      isLiked: payload.isLiked,
      photo: payload.photo,
      name: payload.name,
      active: payload.active,
    }).then( async (res) => {
      if(res.data.msg == 'match users'){
        await addFriend({f:[payload.userId], u: payload.uid, api:apiKey});
        commit('loadingModal', true)
      }
      commit('loadingStatus', false)
      return true;
    })
    .catch(err => {
      commit('loadingStatus', false)
      return false;
    });  
  },
  async setUserSelectedNone({commit, getters}, payload ){
    const { apiKey } = getters.user;
    commit('loadingStatus', true)
    return axios
    .post('/Users/saveUserSelectionNone',{
      userId: payload.userId,
      isLiked: payload.isLiked,
      photo: payload.photo,
      name: payload.name,
      active: payload.active,
    }).then( async (res) => {
      commit('loadingStatus', false)
      return true;
    })
    .catch(err => {
      commit('loadingStatus', false)
      return false;
    });  
  },
  uploadPhotos({ commit, getters, dispatch }, payload) {
    const { apiKey,photos } = getters.user;
    try {
      let dataNames = [];
      let uploadTo = [payload];

      dataNames = [...photos, ...uploadTo];
      return axios.post('/users/update', { photos:dataNames })
        .then(res => {
          if(!res.data){
            return {
              valid: false,
              message: 'error',
            };
          } 
          const newInformationUser = {
            ...getters.user,
            ...res.data,
          }
          commit('setUser', newInformationUser);
          localStorage.setItem('token', res.data.refreshToken);
          const photos = res.data.photos;
          const uid = res.data.id;
          if(photos){
            if(photos[0]){
              const api = apiKey;
              if(api){
                const url = `https://oss.tryfilters.com/${photos[0]}`
                updateUserPhoto({uid,url,api});
              }
            }
          }
          return {
            valid:true,
            data:dataNames,
          };            
        }).catch(err => {
          console.log(err , ' error axios');
          return {
            valid: false,
            message: err,
          };
        });
    } catch (error) {
      console.log(error);
      return {
        valid: false,
        message: error,
      };
    }
  },
  async delPhotos({ commit, getters, dispatch }, payload) {
    commit('loadingStatus', true) 
    try {
      const { photos, apiKey } = getters.user;
      const response = await deleteFile(payload);
      if(response && response.valid){
        const newPhotos = photos.filter(x => x !== payload);
        return axios.post('/users/update', { photos: newPhotos })
        .then(res => {
          if(!res.data){
            commit('loadingStatus', false)
            return {
              valid: false,
              message: 'error',
            };
          } 
          const newInformationUser = {
            ...getters.user,
            ...res.data,
          }
          commit('setUser', newInformationUser);
          localStorage.setItem('token', res.data.refreshToken);
          commit('loadingStatus', false)
          const photos = res.data.photos;
          const uid = res.data.id;
          if(photos){
            if(photos[0]){
              const api = apiKey;
              if(api){
                const url = `https://oss.tryfilters.com/${photos[0]}`
                updateUserPhoto({uid,url,api});
              }
            }
          }
          commit('loadingStatus', false)
          return {
            valid:true,
            data:newPhotos,
          };            })
        .catch(err => {
          console.log(err);
          commit('loadingStatus', false)
          return {
            valid: false,
            message: err,
          };
      });


      }else{
        commit('loadingStatus', false)
        return {
          valid: true,
          message: 'error',
        };
      }
    } catch (error) {
      commit('loadingStatus', false) 
      return {
        valid: false,
        message: error,
      };
    }
  },
  async updateUser({ commit, getters, dispatch }, payload) {
    commit('loadingStatus', true)
    const { apiKey } = getters.user;
    let deletedPhotos = getters.deletedPhotos;
    //SUBIR FOTO
    for await (let i of  getters.photos){
      const photo = i;
      if (!photo) continue;
      try {
        const res = await uploadFile(photo);
        if(res.valid){
          if (deletedPhotos[i]) {
            deletedPhotos[i] = null;
          }else{
            commit('addPhotoName', res.data);
          }   
          commit('loadingStatus', false);
        }else{
          commit('loadingStatus', false);
          return {
            valid: false,
            message: res.data,
          };
        }
      } catch (error) {
        commit('loadingStatus', false)
        console.log(error);
        return {
          valid: false,
          message: error,
        };
      }
    }

    //ELIMINAR FOTO
    let filteredPhotos = [];
    for (let i = 0; i < deletedPhotos.length; i++) {
      const photo = deletedPhotos[i];
      if (!photo) continue;
      await deleteFile(photo);
      filteredPhotos.push(i);
    }

    let { id, photos } = getters.user;

    for (let i = 0; i < filteredPhotos.length; i++) {
      photos.splice(filteredPhotos[i], 1)
    }

    return axios
      .post('/users/update', {
        photos
      })
      .then(res => {
        if(!res.data) return false;
        commit('setUser', res.data);
        localStorage.setItem('token', res.data.refreshToken);
        commit('loadingStatus', false)
        const photos = res.data.photos;
        const uid = res.data.id;
        if(photos){
          if(photos[0]){
            const api = apiKey;
            if(api){
              const url = `https://oss.tryfilters.com/${photos[0]}`
              updateUserPhoto({uid,url,api});
            }
          }
        }
        return true;
      })
      .catch(err => {
        console.log(err);
        commit('loadingStatus', false)
        return false;
      });
    
  },
  async sendUpdateRequest({ commit, getters }, { data, filteredPhotos }) {
    let { id, photos } = getters.user;
    const {
      age,
      bio,
      gender,
      firstName,
      lastName,
      feetHeight,
      inchesHeight,
      zipCode,
      searchFor
    } = data;

    for (let i = 0; i < filteredPhotos.length; i++) {
      photos.splice(filteredPhotos[i], 1);
    }

    return axios
      .post('/users/update', {
        age,
        bio,
        gender,
        firstName,
        lastName,
        feetHeight,
        inchesHeight,
        zipCode,
        searchFor,
        photos
      })
      .then(res => {
        commit('setUser', res.data);
        localStorage.setItem('token', res.data.refreshToken);
        commit('loadingStatus', false)
        return true;
      })
      .catch(err => {
        console.log(err);
        commit('loadingStatus', false)
        return false;
      });
  },
  setModal({commit}){
    commit('loadingModal', false)
  },
  async getUserLike({commit}, id){
    commit('loadingStatus', true)
    return axios
      .get('/users/get',{
        params: {
          id: id
        }
      }).then(res => {
        commit('setUserLike', res.data);
        commit('loadingStatus', false);
        return true;
      }).catch(err => {
        commit('loadingStatus', false);
        return false;
      })
  },
  async deleteUserLike({commit}){
    commit('deleteUserLike')
  },
  async getListLikesUser({commit}, id){
    commit('loadingStatus', true)
    return axios
    .get('/users/likesusers')
    .then(res => {
      commit('setUserLikes', res.data);
      commit('loadingStatus', false)
      return true;
    })
    .catch(err => {
      commit('loadingStatus', false)
      return false;
    });  
  },
  async deleteMyFriend({commit, getters}, payload){
    const { apiKey } = getters.user;
    commit('loadingStatus', true)
    axios.delete('https://207577c306fbca5b.api-us.cometchat.io/v3/users/'+getters.user.id+'/friends',{
      headers: {
        apiKey: apiKey,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: JSON.stringify({friends: [payload]})
    }).then(response => {
      commit('loadingStatus', false);
      router.push('/chat');
    }).catch(error =>  {
      console.log(error);
      commit('loadingStatus', false)
    })
  },
  saveSettings({ commit, getters }) {
    commit('loadingStatus', true)
    const {
      interested,
      zipCode,
      minAgeRange,
      maxAgeRange,
      maxDistance,
      minHeightRange,
      maxHeightRange
    } = getters.filter;
    const { id } = getters.user;
    return axios
      .post('/users/saveSettings', {
        //userId: id,
        interested,
        zipCode,
        minAgeRange,
        maxAgeRange,
        maxDistance,
        minHeightRange,
        maxHeightRange
      })
      .then(res => {
        console.log(res);
        commit('loadingStatus', false)
        return true;
      })
      .catch(err => {
        commit('loadingStatus', false)
        return false;
      });
  },
  async deleteAccount({ commit, getters }) {
    const { apiKey } = getters.user;
    commit('loadingStatus', true)
    const { id } = getters.user;
    return axios
      .get('/Users/get/deleteAccount/'+id)
      .then(async (res) => {
        await deleteUser({id, api: apiKey});
        commit('loadingStatus', false)
        return true;
      })
      .catch(err => {
        commit('loadingStatus', false)
        return false;
      });
  },
};