import Login from '@/pages/Login';
import Signup from '@/pages/Signup';
import Signups from '@/pages/NewSignup';
import Signup3 from '@/pages/NewSignup3';
import ResetPassword from '@/pages/ResetPassword';
import ForgotPassword from '@/pages/ForgotPassword';
import TryPrompts from '@/pages/TryPrompts';
import ViewProfile from '@/pages/ViewProfile';
import Try from '@/pages/Try';
import NewUsers from '@/pages/NewUsers';
import ViewLike from '@/pages/ViewLike';
import Profile from '@/pages/NewProfile';
import Chat from '@/pages/Chat.vue';
import Likes from '@/pages/MatchLikes';
import Settings from '@/pages/Settings';
import Chats from '@/pages/Chats'
import PersonProfile from '@/pages/PersonProfile'
import NotFount from '@/pages/NotFount'

const routes = [
  {
    path: '/',
    redirect: '/signup'
  },
  {
    path: '/likes',
    name: 'likes',
    component: Likes
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/signup',
    name: 'signup',
    component: Signup
  },
  {
    path: '/signup2',
    name: 'signups',
    component: Signups
  },
  {
    path: '/signup3',
    name: 'signups',
    component: Signup3
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/forgot-password/:id',
    name: 'forgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/prompts',
    name: 'prompts',
    component: TryPrompts,
    meta: { requiresAuth: true }
  },
  {
    path: '/view-profile',
    name: 'viewProfile',
    component: ViewProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/lander',
    name: 'Lander',
    component: Try,
    meta: { requiresAuth: false }
  },
  {
    path: '/view-profile-new-users',
    name: 'viewProfileNewUsers',
    component: NewUsers,
    meta: { requiresAuth: false }
  },
  {
    path: '/edit-profile',
    name: 'editProfile',
    component: Profile,
    meta: { requiresAuth: true }
  },
  {
    path: '/chat',
    name: 'chat',
    component: Chat,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: { requiresAuth: true }
  },
  {
    path: '/view-like',
    name: 'viewLike',
    component: ViewLike,
    meta: { requiresAuth: true }
  },
  {
    path: '/chats/:uid',
    component: Chats,
    name: 'chats',
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/profileMatch',
    name: 'profileMatch',
    component: PersonProfile,
    meta: { requiresAuth: true }
  },
  { path: '/:pathMatch(.*)*', beforeEnter: (to, from, next) => { next('/404') } },
  {
    path: '/404',
    name: '404',
    component: NotFount,
  }
];

export default routes;
