<template>
  <div>
    <component :is="componentName" />
    <v-dialog
      v-model="dialog"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title class="th text-h6 text-wrap">
          Find the 1% most likely to be your soulmate
        </v-card-title>
        <v-card-text />
        <v-card-actions style="display: flex; flex-direction: row; justify-content: center;">
          <button
            class="base_button"
            @click="changePage()"
          >
            Start
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  Questions,
  AboutMe,
  Bio,
  Photos,
  FinishAccount,
  Success
} from '@/components/SignUp';
import { questions } from '@/data/questions';

export default {
  name: 'Signup',
  components: {
    Questions,
    AboutMe,
    Bio,
    Photos,
    FinishAccount,
    Success
  },
  data() {
    return {
      dialog: true,
    };
  },
  computed: {
    ...mapGetters(['currentStep']),
    componentName() {
      let aryComponents = [
        'Questions',
        'AboutMe',
        'FinishAccount',
      ];
      let curType = this.currentStep - questions.length + 1;
      if (curType < 0) curType = 0;
      return aryComponents[curType];
    }
  },
  created() {
    this.clearPrompt();
  },
  methods: {
    ...mapMutations(['clearPrompt']),
    changePage(){
      console.log('test');
      this.dialog = false;
    },
  }
};
</script>
<style lang="scss" scoped>

.th{
  font-size: 1.75rem !important;
  font-weight: 700;
  text-align: center;
}
.base_button {
  align-self: center;
  padding: 12px 28px;
  width: 60%;
  //height: 62px;
  background: #009933;
  border: 1.5px solid #009933;
  border-radius: 8px;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  outline-width: 0;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    //width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
