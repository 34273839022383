<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <Load v-if="loadingStatus" />
    <div
      v-if="userMatch"
      class="view-profile-root"
    >
      <div
        v-if="userMatch"
        style="position: relative;"
      >
        <div class="carrusel">
          <div class="imgPrincipal">
            <img
              class="imgIndex"
              :src="`https://oss.tryfilters.com/${userMatch.photos[countImage]}`"
              alt="..."
            >
          </div>
          <v-slide-group
            class="pa-4"
            center-active
            show-arrows
          >
            <v-slide-group-item
              v-for="(slide, index) in userMatch.photos"
              :key="index"
              v-slot="{ active, toggle }"
              class="thumbai"
            >
              <v-card
                style="background: white;"
                class="ma-4"
                height="150"
                width="150"
                @click="onClick(index, toggle)"
              >
                <img
                  style="object-fit: contain; width: 150px; height: 150px;"
                  :src="`https://oss.tryfilters.com/${slide}`"
                >
              </v-card>
            </v-slide-group-item>
          </v-slide-group>
        </div>
  
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${userMatch.photos[0]}`"
          alt="..."
        >
        <v-avatar
          class="cc"
          size="80"
        >
          <div> {{ userMatch.points }} %  </div>
          <div class="ccs">
            match
          </div>
        </v-avatar>
      </div>
      <h3
        v-if="userMatch"
        class="view-profile-root__title"
      >
        {{ userMatch.firstName }} &nbsp;|&nbsp;  {{ userMatch.age }} &nbsp;|&nbsp; {{ userMatch.feetHeight }}' {{ userMatch.inchesHeight }}"
      </h3>
      <v-alert
        v-if="promptsText"
        dense
        prominent
        variant="tonal"
        shaped
        text
        type="success"
        class="alerttxt"
      >
        <div
          class="text--primary text-wrap"
          style="color: black;"
        >
          <strong>{{ labelTextPrompt[0].title }}</strong><br>
          {{ labelTextPrompt[0].text }}
        </div>
      </v-alert> 
      <div style="display: flex; flex-direction: row;align-items: center; justify-content: space-between;">
        <h2 class="ttl">
          Exact Matches
        </h2>
        <v-icon
          :color="'black'"
          @click="mod = true"
        >
          mdi-help-circle
        </v-icon>
      </div>
      <v-dialog
        v-model="mod"
        scrollable
        max-width="300px"
      >
        <v-card>
          <v-card-title style="white-space: normal; ">
            Questions you answered exactly the same during signup
          </v-card-title>
          <v-divider />
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              @click="mod = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        class="view-profile-root__match"
      >
        <div
          v-for="(item,index) in labelMatch"
          :key="index"
          style="text-align: start;"
        >
          <h3 class="tt">
            - {{ item.title }}
          </h3>
          <h4 class="ts">
            {{ item.response }}
          </h4>
        </div>
      </div>
  
      <div>
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${userMatch.photos[1]}`"
          alt="..."
        >
      </div>
      <v-alert
        v-if="promptsText"
        dense
        prominent
        shaped
        text
        variant="tonal"
        type="success"
        class="alerttxt"
      >
        <div
          class="text--primary text-wrap"
          style="color: black;"
        >
          <strong>{{ labelTextPrompt[1].title }}</strong><br>
          {{ labelTextPrompt[1].text }}
        </div>
      </v-alert>
        
      <div style="display: flex; flex-direction: row;align-items: center; justify-content: space-between;">
        <h3 class="ttl">
          Near Matches
        </h3>
        <v-icon
          :color="'black'"
          @click="mod2 = true"
        >
          mdi-help-circle
        </v-icon>
      </div>
      <v-dialog
        v-model="mod2"
        scrollable
        max-width="300px"
      >
        <v-card>
          <v-card-title
            style="white-space: normal; font-size: 21px;"
          >
            Questions where your answers were just  slightly  different
          </v-card-title>
          <v-divider />
          <v-card-actions>
            <v-btn
              color="green darken-1"
              text
              @click="mod2 = false"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div
        class="view-profile-root__match mb"
      >
        <div
          v-for="(item,index) in labelNear"
          :key="index"
          style="text-align: start;"
        >
          <h3 class="tt">
            - {{ item.title }}
          </h3>
          <h4 class="ts">
            {{ item.response }}
          </h4>
        </div>
      </div>
      <div>
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${userMatch.photos[2]}`"
          alt="..."
        >
      </div>
      <v-alert
        v-if="promptsText"
        dense
        outlined
        prominent
        shaped
        text
        variant="tonal"
        type="success"
        class="alerttxt"
        style="margin: 1rem; margin-bottom: 2%;"
        :style="{ 'margin-bottom': (leftPhotos.length < 1) ? 65 + 'px' : 25 +'px' }"
      >
        <div
          class="text--primary text-wrap"
          style="color: black;"
        >
          <strong>{{ labelTextPrompt[2].title }}</strong><br>
          {{ labelTextPrompt[2].text }}
        </div>
      </v-alert>
      <div
        v-for="(photo, index) in leftPhotos"
        :key="index"
        style="margin-bottom: 50px;"
      >
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${photo}`"
          alt="..."
        >
      </div>
  
      <div class="view-profile-root__sticky">
        <div
          class="sticky-item cancel"
          @click="getUser(userMatch.id, false, user.firstName)"
        >
          <img
            class="view-profile-root__avatar"
            src="../assets/action/red.png"
            alt=""
          >
        </div>
        <div
          class="sticky-item accept"
          @click="getUser(userMatch.id, true, user.firstName)"
        >
          <img
            class="view-profile-root__avatar"
            src="../assets/action/green-check.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <v-alert
      v-if="!userMatch"
      color="blue-grey"
      dark
      dense
      icon="mdi-star"
      prominent
      shaped
      class="alert"
    >
      Check back later when there are more people in your area.
    </v-alert>
    <v-dialog
      v-model="loadingModal"
      width="250px"
      style="border-radius: 50px; overflow-x: hidden !important;"
    >
      <v-card>
        <div style="text-align: center;">
          <v-icon
            large
            color="green"
            style="font-size:80px"
          >
            mdi-star
          </v-icon>
          <v-card-text>
            You have a match
          </v-card-text>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="setModal()"
          >
            Ok
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="testaccount"
      scrollable
      persistent
      max-width="500px"
      class="sdt"
    >
      <v-card>
        <v-card-title
          style="white-space: normal; font-size: 24px; font-weight: bold;"
        >
          About Me
        </v-card-title>
        <div class="signup-about-me__form">
          <BaseLabel
            label="I am a"
            class="signup-about-me__label"
          />

          <div
            style="width: 100%;"
            class="d-flex flex-xs-column flex-md-column align-center ccd"
          >
            <v-btn-toggle
              id="btnGroup"
              v-model="genderSelect"
              selected-class="dnrSelected"
              variant="outlined"
              group
              style="width: 100%; height: auto;"
              class="d-flex flex-wrap flex-sm-column flex-md-row align-center"
            >
              <v-btn
                v-for=" (item,index) in genders "
                :key="index"
                :value="item"
                rounded
                class="act flex-grow-1 text-wrap"
              >
                {{ item }}
              </v-btn>
              <v-btn
                rounded
                class="act flex-grow-1"
                @click="openModal()"
              >
                Custom
              </v-btn>
            </v-btn-toggle>
          </div>
          <BaseLabel
            label="Seeking a"
            class="signup-about-me__label"
          />
          <div
            style="width: 100%;"
            class="d-flex flex-xs-column flex-md-column align-center"
          >
            <v-btn-toggle
              id="btnGroup"
              v-model="genderSelect1"
              selected-class="dnrSelected"
              style="width: 100%; height: auto;"
              class="d-flex flex-wrap flex-sm-column flex-md-row align-center"
            >
              <v-btn
                v-for=" (item,index) in genders1"
                :key="index"
                :value="item"
                rounded
                class="act flex-grow-1"
              >
                {{ item }}
              </v-btn>
              <v-btn
                rounded
                class="act flex-grow-1"
                @click="openModal2()"
              >
                Custom
              </v-btn>
            </v-btn-toggle>
          </div>
          <BaseLabel
            label="My age is"
            class="signup-about-me__label"
          />
          <BaseInput
            v-model="ageModel"
            :value="ageModel"
            placeholder="Enter Age"
            :type="'number'"
          />

          <BaseError
            v-if="error"
            :message="txterror"
          />
        </div>

        <v-dialog
          v-model="modg1"
          scrollable
          max-width="300px"
        >
          <v-card>
            <v-card-title>Select Gender</v-card-title>
            <v-divider />
            <v-card-text style="height: 300px;">
              <v-radio-group
                v-model="gender"
                column
              >
                <v-radio
                  label="Agender"
                  value="Agender"
                />
                <v-radio
                  label="Androgynous"
                  value="Androgynous"
                />
                <v-radio
                  label="Bigender"
                  value="Bigender"
                />
                <v-radio
                  label="Gender Fluid"
                  value="Gender Fluid"
                />
                <v-radio
                  label="Gender Nonconforming"
                  value="Gender Nonconforming"
                />
                <v-radio
                  label="Intersex"
                  value="Intersex"
                />
                <v-radio
                  label="Trans Man"
                  value="Trans Man"
                />
                <v-radio
                  label="Trans Woman"
                  value="Trans Woman"
                />
                <v-radio
                  label="Non-binary"
                  value="Non-binary"
                /> 
                <v-radio
                  label="Other"
                  value="Other"
                />
              </v-radio-group>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-btn
                color=" darken-1"
                text
                @click="modg1 = false"
              >
                Close
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="saveGender()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="modg2"
          scrollable
          max-width="300px"
        >
          <v-card>
            <v-card-title>Select Gender</v-card-title>
            <v-divider />
            <v-card-text style="height: 300px;">
              <v-radio-group
                v-model="gender1"
                column
              >
                <v-radio
                  label="Agender"
                  value="Agender"
                />
                <v-radio
                  label="Androgynous"
                  value="Androgynous"
                />
                <v-radio
                  label="Bigender"
                  value="Bigender"
                />
                <v-radio
                  label="Gender Fluid"
                  value="Gender Fluid"
                />
                <v-radio
                  label="Gender Nonconforming"
                  value="Gender Nonconforming"
                />
                <v-radio
                  label="Intersex"
                  value="Intersex"
                />
                <v-radio
                  label="Trans Man"
                  value="Trans Man"
                />
                <v-radio
                  label="Trans Woman"
                  value="Trans Woman"
                />
                <v-radio
                  label="Non-binary"
                  value="Non-binary"
                /> 
                <v-radio
                  label="Other"
                  value="Other"
                />
              </v-radio-group>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-btn
                color=" darken-1"
                text
                @click="modg2 = false"
              >
                Close
              </v-btn>
              <v-btn
                color="green darken-1"
                text
                @click="saveGender2()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <br>
        <v-divider />
        <br>

        <v-card-actions>
          <BaseButton
            class="signup-about-me__submit"
            text="Try Test Account"
            @my-event="submit()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
  import { CometChat } from '@cometchat-pro/chat';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import { Load } from '@/components/Element';
  import { questions } from '@/data/questions';
  import { prompts } from '@/data/prompts';
  import { useRoute } from 'vue-router';
  import { BaseButton, BaseError, BaseInput, BaseLabel, BaseSelect2, } from '@/components/Element';
  
  export default {
    name: 'ViewProfile',
    components:{
        Load,
        BaseButton,
        BaseError,
        BaseInput,
        BaseLabel,
    },
    inheritAttrs: false,
    data(){
      return{ 
        us:null,
        count:0,
        countImage: 0,
        testaccount: true,
        dialog: false,
        dialogPhoto: false,
        mod: false,
        mod2: false,
        asNavFor1: [],
        asNavFor2: [],
        options1: {
          dots: false,
          fade: true,
          navButtons: false
        },
        options2: {
          autoplay: true,
          centerMode: true,
          dots: false,
          navButtons: false,
          slidesToShow: 3,
        },
        slides: [
          'https://images.unsplash.com/photo-1453831362806-3d5577f014a4?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
          'https://images.unsplash.com/photo-1496412705862-e0088f16f791?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
          'https://images.unsplash.com/photo-1506354666786-959d6d497f1a?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
          'https://images.unsplash.com/photo-1455619452474-d2be8b1e70cd?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
          'https://images.unsplash.com/photo-1504674900247-0877df9cc836?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
          'https://images.unsplash.com/photo-1472926373053-51b220987527?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ',
          'https://images.unsplash.com/photo-1497534547324-0ebb3f052e88?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600&fit=max&ixid=eyJhcHBfaWQiOjE0NTg5fQ'
        ],
        toast: false,
        modg1: false,
        modg2: false,
        gender: null,
        genderSelect: '',
        genders: ['Man', 'Woman'],

        gender1: null,
        genderSelect1: '',
        genders1: ['Man', 'Woman'],
        textbtn: null,
        error: false,
        txterror: 'All fields are required!'
      }
    },
    computed: {
      ...mapGetters(['user', 'userMatch', 'loadingStatus', 'loadingModal', 'isAboutMeValidNewTry']),
      leftPhotos() {
        if(!this.userMatch?.photos) {
          return [];
        }
        return this.userMatch?.photos.slice(3);
      },
      labelTextPrompt() {
        if(!this.userMatch?.prompts) {
          return [];
        }
        if(this.userMatch?.prompts?.length < 1) {
          return [];
        }
        let newArray = [];
        const dataPrompt = this.userMatch?.prompts;
        // eslint-disable-next-line no-unused-vars
        dataPrompt?.forEach((element, index) => {
          const id = prompts?.findIndex(x => x.id === element.id);
          if(id >= 0){
            newArray.push({
              id: element?.id,
              title: prompts[id]?.title,
              text: element?.text,
            })
          }
        });
        return newArray;
      },
      leftPrompts() {
        if(!this.userMatch?.prompts) {
          return [];
        }
        if(this.userMatch?.prompts?.length < 1) {
          return [];
        }
        let newArray = [];
        const dataPrompt = this.userMatch?.prompts.slice(3);
        // eslint-disable-next-line no-unused-vars
        dataPrompt?.forEach((element, index) => {
          const id = prompts?.findIndex(x => x.id === element.id);
          if(id >= 0){
            newArray.push({
              id: element?.id,
              title: prompts[id]?.title,
              text: element?.text,
            })
          }
        });
  
        return newArray;
      },
      promptsText(){
        if(this.labelTextPrompt.length > 0){
          return true;
        }
        return false;
      },
      labelNear(){
        let array = []
        this.userMatch?.optionsAnswerNear?.forEach(element => {
          if(questions[element.question_id -1]?.options[element.answer_number] == 'Neutral'){
            const data = {
              title: questions[element.question_id -1]?.title,
              response: questions[element.question_id -1]?.options[element.answer_number]
            };
            array.push(data);
          }else{
            const data = {
              title: questions[element.question_id -1]?.title,
              response: questions[element.question_id -1]?.options[element.answer_number]
            };
            array.push(data);
          }
        });
        return array;
      },
      labelMatch(){
        let array = []
        this.userMatch?.optionsAnswerExact?.forEach(element => {
          if(questions[element.question_id -1]?.options[element.answer_number] == 'Neutral'){
            const data = {
              title: questions[element.question_id -1]?.title,
              response: questions[element.question_id -1]?.options[element.answer_number]
            };
            array.push(data);
          }else{
            const data = {
              title: questions[element.question_id -1]?.title,
              response: questions[element.question_id -1]?.options[element.answer_number]
            };
            array.push(data);
          }
        });
        return array;
      },
      txt(){
        return this.userMatch?.bio?.replace(/\\n/g, '<br >');
      },
      currentRouteName() {
        const route = useRoute()
        let name = route.name;
        return name;
      },
      ageModel: {
        get: vm => vm.user.age + '',
        set(value) {
          this.setAge(parseInt(value));
        }
      },
      genderModel: {
        get: vm => vm.user.gender,
        set(value) {
          this.setGender(value);
        }
      },
      genderModel2: {
        get: vm => vm.user.gender,
        set(value) {
          this.setGender(value);
        }
      },
      searchForModel: {
        get: vm => vm.user.searchFor,
        set(value) {
          this.setSearchFor(value);
        }
      },
    },
    watch: {
      genderSelect: function (newValue, oldValue) {
        this.setGender(newValue);
      },
      genderSelect1: function (newValue, oldValue) {
        this.setSearchFor(newValue);
      }
    },
    mounted () {
      this.asNavFor1.push(this.$refs.thumbnails)
      this.asNavFor2.push(this.$refs.main)
    },
    async created(){
      await this.getListUserMatchTry();
      this.test();
    },
    methods: {
      ...mapActions(['logout', 'getListUserMatchTry', 'deleteUserMatch', 'setUserSelected', 'setUserSelectedNone', 'setModal', 'deleteEmailUser', 'sendRegisterRequestTry']),
      ...mapMutations([
        'setAge',
        'setCurrentStep',
        'setFirstName',
        'setLastName',
        'setGender',
        'setHeightFeet',
        'setHeightInches',
        'setSearchFor',
        'setZipCode'
      ]),
      txtname(txt){
        return txt+'    ';
      },
      async test() {
        let nameRoute = window.location.pathname ?? '';
        if(!this.user.photos || this.user.photos.length <= 0){
          this.toast = true;
          setTimeout(() => {
            this.toast = false;
          }, 4000);
  
          if(nameRoute === '/view-profile-new-users'){
            setTimeout(() => {
              this.dialogPhoto = true;
            }, 30000);
          }
        }
      },
      goProfile(){
        this.$router.push({
          name: 'editProfile'
        });
      },
      async scrollTop(){
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      },
      async getUser(id,bol,nam){
        if(this.userMatch == undefined ) return;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
        
        /*if(this.user.photos && this.user.photos.length > 0){
          await this.setUserSelected({
            userId: id,
            isLiked: bol,
            photo: this.user.photos[0],
            name: nam,
            uid: this.user.id,
            active: true
          }); 
        }else{
          await this.setUserSelectedNone({
            userId: id,
            isLiked: bol,
            photo: undefined,
            name: nam,
            uid: this.user.id,
            active: false
          }); 
        }*/
        
        await this.deleteUserMatch() 
        await this.scrollTop();
        this.test() 
      },
      onClick(index, toggle){
        toggle();
        this.countImage = index;
        return;
      },
      changePage(){
        this.$router.push({
          name: 'editProfile'
        });
        this.dialogPhoto = false;
      },
        async openModal(){
            this.modg1 = true;
        },
        async openModal2(){
            this.modg2 = true;
        },
        async saveGender(){
            this.genders = ['Man', 'Woman'];
            this.genderSelect = this.gender;
            this.genders.push(this.gender);
            this.modg1 = false;
            if(this.genders.length);
        },
        async saveGender2(){
            this.genders1 = ['Man', 'Woman'];
            this.genderSelect1 = this.gender1;
            this.genders1.push(this.gender1);
            this.modg2 = false;
            if(this.genders1.length);
      },
      async submit() {
        if(this.isAboutMeValidNewTry == false){
          this.txterror = 'All fields are required!';
          this.error = true;
          return;
        }
        if(this.isAboutMeValidNewTry !== true){
          this.txterror = 'Field Required: \n' + this.isAboutMeValidNewTry;
          this.error = true;
          return;
        }

        if (!this.isAboutMeValidNewTry) {
          this.txterror = 'All fields are required!'
          this.error = true;
          return;
        }
        this.error = false;
        console.log(this.isAboutMeValidNewTry)
        this.submitTry();
      },
      async submitTry() {
        //this.loadStatus = true;
        try {
          const res = await this.sendRegisterRequestTry(); 
          if(res.valid === true){
            let authKey = this.user.authKey;
            var username = this.user.id;
            var name = 'userTemp';
            var user = new CometChat.User(username);
            user.setName(name);
            CometChat.createUser(user, authKey).then(
              user => {
                CometChat.login(username, authKey).then(
                  data => {
                    this.$router.push({
                      name: 'viewProfileNewUsers'
                    });
                  }, 
                  error => {
                    alert(
                      'Whoops. Something went wrong.'
                    );
                    this.error = ('Login failed. Try in login page.');
                  }
                ).catch(err => {
                  this.error = ('Login failed. Try in login page.');
                })
              }, async (error) => {
                const response = await this.deleteEmailUser(this.user.id);
                if(response){
                  this.error = ('Register failed. Try again');
                }else{
                  this.error = ('Register failed. Try again with other email');
                }
              }
            ).catch( async (error) => {
              alert(
                "Whoops. Something went wrong. This commonly happens when you enter a username that doesn't exist. Check the console for more information"
              );
              const response = await this.deleteEmailUser(this.user.id);
              if(response){
                this.error = ('Register failed. Try again');
              }else{
                this.error = ('Register failed. Try again with other email');
              }
            })
          }else{
            console.log(res?.message);
            this.error = res.message || 'Register Failed, Try again';
            if(res && res?.message === 'Password is weak'){
              this.error = 'Password must be at least 8 characters'
            }
            if(res && res?.message === 'Email already exist'){
              this.erroremail = 'Email already exists.  Try logging in'
              this.error = null;
            }
            return
          }
        } catch (error) {
          console.log(error);
        }
      },
    },
  };
</script>
  
<style lang="scss" scoped>

    .sdt{
        backdrop-filter: blur(5px);
    }

  .view-profile-root {
    //width: 100%;
  
    .alerttxt{
      margin: 1rem; margin-bottom: 1%; border-color: #009933; border-width: 1px; border-style: solid; border-top-left-radius: 25px; border-bottom-right-radius: 25px;
    }
  
    @media (max-width: 600px) {
      width: 100vw;
      //overflow-x:hidden;
    }
  
    @media (min-width: 600px) {
      margin-right: 25%;
      margin-left: 25%;
    }
  
    .cc{
      position: absolute;
      //padding: 10px;
      background: #009933;
      font-weight: bold;
      color:white;
      font-size: 26px;
      top: 8px;
      right: 16px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
  
      @media (min-width: 400px) {
        //padding: 90px;
        width: 120px !important;
        height: 120px !important;
        font-size: 34px;
      }
  
      .ccs{
        font-size: 12px;
        @media (min-width: 400px) {
          font-size: 16px;
        }
      }
    }
  
    .carrusel{
      display: block;
      //background: red;
      @media (max-width: 600px) {
        display: none;
      }
  
      .imgIndex{
        width: 100%;
        max-height: 50vh;
        object-fit: contain;
      }
  
      .imgMiniatura{
        width: 100%;
        //background: green;
        display: flex;
        flex-direction: row;
        overflow-x: scroll;
        justify-content: space-between;
        padding-left: 2px;
        padding-right: 2px;
  
        .thumbai{
          cursor: pointer;
          margin-right: 10px;
          &:hover{
            color: gray;
          }
        }
      }
    }
  
    &__btn {
      position: fixed;
      top: 1rem;
      right: 1rem;  
    }
    &__image {
      position: relative;
      width: 100%;
      max-height: 50vh;
      object-fit: contain;
      
      @media (min-width: 600px) {
        display: none;
      }
    }
    &__title {
      padding: 1rem;
      font-size: 24px;
      line-height: 35px;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.09);
    }
    &__match {
      padding: 1rem;
      line-height: 2rem;
  
      @media (min-width: 600px) {
        font-size:20px;
      }
    }
  
    .txtMatch{
      padding: 1rem;
      line-height: 2rem;
    }
  
    .tt{
      font-size: 16px;
      font-weight: 400;
      @media (min-width: 600px) {
        font-size:16px;
        font-weight: 400;
      }
    }
  
    .ttl{
      margin-left: 15px;
      margin-top: 5px;
      @media (min-width: 600px) {
        font-size:22px;
      }
    }
  
    .ts{
      font-size: 16px;
      font-weight: 600;
      @media (min-width: 600px) {
        font-size:18px;
        font-weight: 600;
      }
    }
  
    .mb{
      margin-bottom: 60px;
  
      @media (min-width: 600px) {
        margin-bottom: 10px;
      }
    }
  
    &__container {
      padding: 0;
      margin: 0;
    }
    &__col {
      display: flex;
      align-items: center;
    }
    &__text {
      word-break: break-word;
      margin-left: 0.3rem;
    }
    &__avatar {
      border-radius: unset;
      height: unset;
      width: unset;
    }
    &__sticky {
      width: 100%;
      position: sticky;
      bottom: 0;
      display: flex;
      
      @media (max-width: 600px) {
        position:fixed !important;
        margin-top: 20px;
        //position: -webkit-sticky;
        //position: sticky;
      }   
      .sticky-item {
        flex: 1;
        display: flex;
        justify-content: center;
        padding: 5px;
      }
      .accept {
        flex:1;
        //background: #b8b8b892;
        //background: rgb(0, 153, 51, 0.438);
        backdrop-filter: blur(5px);
        cursor:pointer;
      }
      .cancel {
        flex: 1;
        //background: #b8b8b892;
        //background: rgba(172, 21, 21, 0.438);
        //background: rgba(172, 21, 21);
        backdrop-filter: blur(5px);
        cursor:pointer;
      }
    }
  
  
  .main{
    margin-bottom: 10px;
  }
  .thumbnails{
    
    margin: 0 50px;
    width: calc(100% + 10px);
  }
  
  // Basic VueAgile styles
  .agile{
    &__nav-button{
      background: transparent;
      border: none;
      color: #ccc;
      cursor: pointer;
      font-size: 24px;
      transition-duration: .3s;
    
      .thumbnails & {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
  
        &--prev{
          left: -45px;
        }
        &--next{
          right: -45px;
        }
      }
      &:hover{
        color: #888;
      }
    }
    &__dot{
      margin: 0 10px;
  
      button{
        background-color: #eee;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        display: block;
        height: 10px;
        font-size: 0;
        line-height: 0;
        margin: 0;
        padding: 0;
        transition-duration: .3s;
        width: 10px;
      }
      &--current,
      &:hover{
        button{
          background-color: #888
        }
      }
    }
  }
  
  // Slides styles  
  .slide{
    align-items: center;
    box-sizing: border-box;
    color: #fff;
    display: flex;
    height: 400px;
    justify-content: center;
  
    &--thumbniail {
      cursor: pointer;
      height: 100px;
      padding: 0 2px;
      transition: opacity .3s;
  
      &:hover{
        opacity: .75;
      }
    }
    img{
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  
  }
  .alert{
    position: sticky;
    bottom: 0;
    margin:15px;
    margin-top:20px;
  
    @media (min-width: 800px) {
      margin:35px;
    }
  }
  
  
  .th{
    font-size: 1.75rem !important;
    font-weight: 700;
    text-align: center;
  }
  .base_button {
    align-self: center;
    padding: 12px 28px;
    width: 60%;
    //height: 62px;
    background: #009933;
    border: 1.5px solid #009933;
    border-radius: 8px;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    color: #fff;
    outline-width: 0;
    cursor: pointer;
    margin-bottom: 20px;
  
    @media (max-width: 500px) {
      //width: 100%;
    }
  
    &:hover {
      opacity: 0.8;
    }
  }


  .signup-about-me {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 450px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: calc(100% - 40px);
      align-items: flex-start;
    }

    .v-btn{
    border-style: solid;
  }
  #btnGroup .dnrSelected {
    background-color: #009933 !important;
    color: white !important;
    border-radius: 4px !important;
    border-color: #009933  !important;
  }

  #btnGroup .act{
    width: 70px;
    color: black;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(190, 190, 190);
    background: white;
    margin-right: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding: 13px;
  }

  }

  &__label {
    margin-top: 30px;

    @media (max-width: 500px) {
      font-size: 22px;
    }    
  }

  .lb{
    width: fit-content;
    color: black;
    background-color: red;
    border-radius: 5px !important;
    border-width: 1px !important;
    border-color: rgb(213, 105, 105) !important;
    margin-right: 15px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &__two {
    display: flex;
    justify-content: space-between;
    width: 450px;
    padding-top: 6px;
    //background: red;
    .base-input {
      width: calc(50% - 10px);
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    .secu{
      background: rgb(255, 255, 255);
      width: 50%;
      height: fit-content;
      border-color: rgb(49, 49, 49);
      border-style: solid;
      border-width: 1px;
      padding: 10px;
      border-radius: 10px;
    }
  }

  &__submit {
    margin-top: 20px;
    width: 100%;
  }

  .v-btn{
    border-style: solid;
  }
  #btnGroup .dnrSelected {
    background-color: #009933 !important;
    color: white !important;
    border-radius: 4px !important;
    border-color: #009933  !important;
  }

  #btnGroup .act{
    width: 70px;
    color: black;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(190, 190, 190);
    background: white;
    margin-right: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding: 13px;
  }
}
</style>
  