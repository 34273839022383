export const prompts = [
    {
        id: 1,
        title: 'A non-negotiable is…',
    },
    {
        id: 2,
        title: 'A pro and con of dating me...',
    },
    {
        id: 3,
        title: 'After work you can find me...',
    },
    {
        id: 4,
        title: 'As a child, I was really into...',
    },
    {
        id: 5,
        title: 'Favorite quality in a person…',
    },
    {
        id: 6,
        title: 'I get way too excited about…',
    },
    {
        id: 7,
        title: "I promise I won't judge you if...",
    },
    {
        id: 8,
        title: 'I quote too much from...',
    },
    {
        id: 9,
        title: 'If I could have a super power it would be...',
    },
    {
        id: 10,
        title: "If you laugh at this, we'll get along...",
    },
    {
        id: 11,
        title: 'If I could travel to any time in the past it would be…',
    },
    {
        id: 12,
        title: "I'm hoping you...",
    },
    {
        id: 13,
        title: "I'm still not over...",
    },
    {
        id: 14,
        title: 'I will never shut up about…',
    },
    {
        id: 15,
        title: 'My favorite quality in a person is',
    },
    {
        id: 16,
        title: 'My perfect first date is...',
    },
    {
        id: 17,
        title: 'My personal hell is:',
    },
    {
        id: 18,
        title: 'My real life super power is...',
    },
    {
        id: 19,
        title: 'One thing you should know about me is..',
    },
    {
        id: 20,
        title: 'Something I learned way later than I should have...',
    },
    {
        id: 21,
        title: 'The quickest way to my heart is...',
    },
    {
        id: 22,
        title: 'The world would be a better place with more...',
    },
    {
        id: 23,
        title: 'Two truths and a lie...',
    },
    {
        id: 24,
        title: "We'll get along if…",
    },
    {
        id: 25,
        title: 'What makes a relationship great is…',
    },
    {
        id: 26,
        title: 'What I order for the table…',
    },
    {
        id: 27,
        title: "We're the same type of weird if…",
    },
    {
        id: 28,
        title: 'You should *not* go out with me if…',
    },
    {
        id: 29,
        title: 'My happy place is…',
    },
]