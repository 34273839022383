<template>
  <select
    v-model="selectedValue"
    class="base-select"
  >
    <option
      value=""
      disabled
    >
      {{ label }}
    </option>
    <option
      v-for="choice in choices"
      :key="choice"
      :value="choice"
    >
      {{ choice }}
    </option>
  </select>
</template>

<script>
  export default {
    name: 'BaseSelect2',
    props: {
      choices: {
        type: Array,
        required: true,
      },
      value: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
    },
    computed: {
      selectedValue: {
        get: vm => vm.value,
        set(v) {
          this.$emit('input', v);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .base-select {
    padding: 12px 28px 12px 16px;
    width: 218px !important;
    height: 62px;
    border: 1.5px solid rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    outline-width: 0;
    margin-top: 10px;
    color: rgb(0, 0, 0) !important;
    margin-left: 2px;
    margin-right: 2px;

    @media (max-width: 500px) {
      width: 153px !important;
    }
  }
</style>