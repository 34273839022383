<template>
  <div class="base-header">
    {{ title }}
    <div
      v-if="showScore"
      class="base-header__score"
    />
  </div>
</template>

<script>
  export default {
    name: 'BaseHeader',
    props: {
      title: {
        type: String,
        required: true,
      },
      showScore: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .base-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.07em;
    color: #000;
    margin-top: 10px;
    //height: 50px;
    //box-shadow: 0 15px 30px rgba(0, 0, 0, 0.06);
    text-align: center;
    padding: 0 20px;
    max-width: 900px;

    @media (max-width: 500px) {
      box-shadow: none;
      font-size: 20px;
      line-height: 30px;
    }

    &__score {
      position: absolute;
      width: 60px;
      height: 60px;
      left: calc(50% + 120px);
      top: 23.52px;
      background-color: #009933;
      border-radius: 50%;
    }
  }
</style>
