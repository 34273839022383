<template>
  <v-alert
    shaped
    prominent
    type="error"
  >
    !Whoops. Route not found.¡
  </v-alert>
</template>

<script>

export default {
  name: 'NoFount',
};

</script>

<style scoped>

</style>