import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes';
import store from '@/store';

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = store.getters['user'];
    if (user.jwtToken && user.jwtToken !== '') {
      next();
    } else {
      const refreshToken = await store.dispatch('refreshToken');
      if (refreshToken) {
        next();
      } else {
        next({ name: 'login' });
      }
    }
  } else {
    next();
  }
});

export default router
