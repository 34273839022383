<template>
  <div>
    <Load v-if="loadingStatus" />
    <div
      v-if="userLike"
      class="view-profile-root"
    >
      <div
        v-if="userLike"
        style="position: relative;"
      >
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${userLike.photos[0]}`"
          alt="..."
        >
        <div class="cc">
          {{ userLike.points }}
        </div>
      </div>
      <h3
        v-if="userLike"
        class="view-profile-root__title"
      >
        {{ userLike.firstName }}, {{ userLike.age }}
      </h3>
      <div class="view-profile-root__match">
        <h3>
          Exact Matches
        </h3>
        <v-container class="view-profile-root__container lighten-5">
          <h4
            v-for="(item,index) in labelMatch"
            :key="index"
          >
            - {{ item }}
          </h4>
        </v-container>
        <div />
      </div>
      <div>
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${userLike.photos[1]}`"
          alt="..."
        >
      </div>
      <h3 class="view-profile-root__title">
        {{ userLike.feetHeight }}' {{ userLike.inchesHeight }}"
      </h3>
      <div class="view-profile-root__match">
        <h3>
          About Me
        </h3>
        <div>
          <p style="white-space: pre-line">
            {{ userLike.bio }}
          </p>
        </div>
      </div>
      <div>
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${userLike.photos[2]}`"
          alt="..."
        >
      </div>
      <h3 class="view-profile-root__title">
        Near Matches
      </h3>
      <div
        class="view-profile-root__match mb"
      >
        <h4
          v-for="(item,index) in labelNear"
          :key="index"
        >
          - {{ item }}
        </h4>
      </div>
      <div
        v-for="(photo, index) in leftPhotos"
        :key="index"
        style="margin-bottom: 30px;"
      >
        <img
          class="view-profile-root__image"
          :src="`https://oss.tryfilters.com/${photo}`"
          alt="..."
        >
      </div>
      <div class="view-profile-root__sticky">
        <div
          class="sticky-item cancel"
          @click="getUser(userLike.id, false, user.photos[0], user.firstName)"
        >
          <img
            class="view-profile-root__avatar"
            src="../assets/action/w-cancel.png"
            alt=""
          >
        </div>
        <div
          class="sticky-item accept"
          @click="getUser(userLike.id, true, user.photos[0], user.firstName)"
        >
          <img
            class="view-profile-root__avatar"
            src="../assets/action/w-accept.png"
            alt=""
          >
        </div>
      </div>
    </div>
    <v-alert
      v-if="!userLike"
      color="blue-grey"
      dark
      dense
      icon="mdi-star"
      prominent
      shaped
      class="alert"
    >
      You have a match
    </v-alert>
    <v-dialog
      v-model="loadingModal"
      width="250px"
      style="border-radius: 50px; overflow-x: hidden !important;"
    >
      <v-card>
        <div style="text-align: center;">
          <v-icon
            large
            color="green"
            style="font-size:80px"
          >
            mdi-star
          </v-icon>
          <v-card-text>
            You have a match
          </v-card-text>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="okModal()"
          >
            Ok
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Load } from '@/components/Element';
import { questions } from '@/data/questions';

export default {
  name: 'ViewLike',
  components:{
    Load
  },
  data(){
    return{ 
      us:null,
      count:0,
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['user', 'userMatch', 'loadingStatus', 'loadingModal', 'userLike']),
    leftPhotos() {
      if(!this.userLike.photos) {
        return [];
      }
      return this.userLike.photos.slice(3);
    },
    labelNear(){
      let array = []
      this.userLike.optionsAnswerNear.forEach(element => {
        if( questions[element.question_id -1]?.options[element.answer_number] == 'Neutral'){
          array.push(questions[element.question_id -1]?.options[element.answer_number] + ' - '+ questions[element.question_id -1].title );
        }else{
          array.push(questions[element.question_id -1]?.options[element.answer_number]);
        }
      });
      return array;
    },
    labelMatch(){
      let array = []
      this.userLike.optionsAnswerExact.forEach(element => {
        if( questions[element.question_id -1]?.options[element.answer_number] == 'Neutral'){
          array.push(questions[element.question_id -1]?.options[element.answer_number] + ' - '+ questions[element.question_id -1].title );
        }else{
          array.push(questions[element.question_id -1]?.options[element.answer_number]);
        }
      });
      return array;
    }
  },
  async created(){
    this.test();
  },
  methods: { 
    ...mapActions(['logout', 'getListUserMatch', 'deleteUserMatch', 'setUserSelected', 'setModal', 'deleteUserLike']),
    async test() {
    },
    async getUser(id,bol,pho,nam){
      if(this.userLike == undefined ) return;
      await this.setUserSelected({
        userId: id,
        isLiked: bol,
        photo: pho,
        name: nam,
        uid: this.user.id
      });
      await this.deleteUserLike()
      if(!bol){
        this.$router.push({
          name: 'chat', 
        })
      }
      this.test()
    },
    async okModal(){
      await this.setModal();
      this.$router.push({
        name: 'chat', 
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.view-profile-root {

  @media (max-width: 500px) {
    width: 100vmin;
  }

  @media (min-width: 700px) {
    margin-right: 23%;
    margin-left: 23%;
  }

  .cc{
    position: absolute;
    padding: 10px;
    background: #009933;
    font-weight: bold;
    color:white;
    font-size:22px;
    top: 8px;
    right: 16px;
    border-radius: 50%;

    @media (min-width: 600px) {
      padding: 4%;
      font-size:25px;;
    }
  }

  &__btn {
    position: fixed;
    top: 1rem;
    right: 1rem;
  }
  &__image {
    width: 100%;
    max-height: 50vh;
    object-fit: contain;
  }
  &__title {
    padding: 1rem;
    font-size: 24px;
    line-height: 35px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.09);
  }
  &__match {
    padding: 1rem;
    line-height: 2rem;
     @media (min-width: 600px) {
      font-size:20px;
    }
  }
  .mb{
    margin-bottom: 60px;

    @media (min-width: 600px) {
      margin-bottom: 10px;
    }
  }
  &__container {
    padding: 0;
    margin: 0;
  }
  &__col {
    display: flex;
    align-items: center;
  }
  &__text {
    word-break: break-word;
    margin-left: 0.3rem;
  }
  &__avatar {
    border-radius: unset;
    height: unset;
    width: unset;
  }
  &__sticky {
    width: 100%;
    position: sticky;
    bottom: 0;
    display: flex;
    @media (max-width: 600px) {
      position:fixed !important;
      margin-top: 20px;
    }

    .sticky-item {
      flex: 1;
      display: flex;
      justify-content: center;
      padding: 5px;
    }

    .accept {
      flex:1;
      background: #009933;
      cursor:pointer;
    }

    .cancel {
      flex: 1;
      background: #AC1515;
      cursor:pointer;
    }
  }
}
.alert{
  position: sticky;
  bottom: 0;
  margin:15px;
  margin-top:20px;
}
</style>