<template>
  <div>
    <div style="width: 100%; position: absolute; top: 20px;">
      <a
        href="https://tryfilters.com/"
        class="tt"
      >Filters</a>
    </div>
    <SignUpStep
      title="Login"
      class="login"
      :tt-head="false"
    > 
      <div class="login__form">
        <BaseError
          v-if="error !== null"
          :message="error"
        />
        <BaseLabel
          label="Email"
          class="login__label"
        />
        <BaseInput
          v-model="email"
          :value="email"
          placeholder="Enter your email address"
          class="login__input"
        />
        <BaseLabel
          label="Password"
          class="login__label"
        />
        <BaseInput
          v-model="password"
          :value="password"
          placeholder="Enter your Password "
          type="password"
          class="login__input"
        />

        <BaseButton
          class="login__submit"
          text="Log In"
          @my-event="submit()"
        />
        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
          <div>
            <router-link
              style="align-self: flex-start;"
              :to="{ name: 'resetPassword' }"
              class="float-right"
            >
              Reset Password
            </router-link>
          </div>
          <div>
            <router-link
              style="align-self: flex-end;"
              :to="{ name: 'signup' }"
              class="float-right"
            >
              Create Account
            </router-link>
          </div>
        </div>
      </div>
      <Loader v-if="loadStatus" />
    </SignUpStep>
  </div>
</template>

<script>
import { CometChat } from '@cometchat-pro/chat';
import { mapGetters, mapActions } from 'vuex';
import {
  BaseError,
  BaseButton,
  BaseInput,
  BaseLabel,
  Loader
} from '@/components/Element';
import SignUpStep from '@/components/Layout/SignUpStep';
export default {
  name: 'FinishAccount',
  components: {
    BaseError,
    BaseButton,
    BaseInput,
    BaseLabel,
    SignUpStep,
    Loader
  },
  data(){
    return {
      email: '',
      password: '',
      error: null,
      loadStatus: false
    };
  },
  computed:{
    ...mapGetters(['loadingStatus', 'user'])
  },
  created(){
  },
  methods: {
    ...mapActions(['login']),
    async submit(){
      if (!this.email) {
        this.error = 'Email Address is required';
        return;
      }

      if (!/.+@.+\..+/.test(this.email)) {
        this.error = 'Email Address is not valid';
        return;
      }

      if (!this.password) {
        this.error = 'Password is required';
        return;
      }

      this.loadStatus = true;
      try {
        const res = await this.login({
          email: this.email,
          password: this.password 
        });
        if (res.valid) {
          let authKey = this.user.authKey;
          var username = this.user.id; 
          CometChat.login(username, authKey).then(
            data => {
              this.loadStatus = false;
              this.$router.push({
                name: 'viewProfile'
              });
              return;
            },
            error => {
              console.log(error);
              this.loadStatus = false;
              alert(
                'Whoops. Something went wrong.'
              );
              return;
            }
          );
        } else {
          this.loadStatus = false;
          if(res.ms){
            this.error = 'Login Failed.  ' + res.ms;
          }
        } 
      } catch (error) {
        console.log('error', error);
        this.loadStatus = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>

  .tt{
    margin-top: 2px;
    margin-left: 50px;
    color: #009933; 
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.07em;
    cursor: pointer;
    text-decoration: none;

    @media (max-width: 500px) {
      margin-left: 20px;
      box-shadow: none;
      font-size: 20px;
      line-height: 30px;
    }
  }
.login {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    width: 450px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: calc(100% - 40px);
      padding-top: 30px;
    }
  }

  &__label {
    margin-top: 30px;

    &:not(:first-child) {
      margin-top: 60px;
    }

    @media (max-width: 500px) {
      margin: 0;
      font-size: 24px;
      text-align: left;
    }
  }

  &__submit {
    margin-top: 40px;
  }

  .info-message {
    margin-left: 50px;
  }
}
</style>
