<template>
  <SignUpStep
    class="signup-success"
    :show-score="true"
  >
    <div class="signup-success__container">
      <!--<div class="signup-success__arrow">
        <img
          src="../../assets/arrow.svg"
          alt="..."
        >
      </div> -->
      <h3 class="signup-success__title">
        Now you will see profiles of the people you have the most in common with.
      </h3>      
      <br>
      <p class="signup-success__content">
        There will be a score in the upper right corner that measures compatability.
      </p>
      <!--
      <SuccessNote
        img="warning.svg"
        note="Don’t go to fast because there are only so many people who share your interests."
      /> -->
      <BaseButton
        class="signup-success__start"
        text="Start"
        @my-event="submit()"
      />
    </div>
  </SignUpStep>
</template>

<script>
  import SignUpStep from '@/components/Layout/SignUpStep';
  //import SuccessNote from '@/components/SignUp/SuccessNote';
  import { BaseButton } from '@/components/Element';
  import { mapMutations } from 'vuex';

  export default {
    name: 'Success',
    components: {
      BaseButton,
      SignUpStep,
      //SuccessNote,
    },
    methods: {
      ...mapMutations(['setCurrentStep']),
      submit() {
        this.$router.push({
          name: 'viewProfile'
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .signup-success {
    &__container {
      padding: 20px 20px 0;
      width: 350px;
      margin: 0 auto;
      position: relative;

      @media (min-width: 600px) {
        width: 450px;
      }
    }

    &__arrow {
      position: absolute;
      right: 20px;
      top: -10px;
    }

    &__title {
      font-size: 24px;
      line-height: 35px;
      width: 100%;
      //background: red;

      @media (min-width: 600px) {
        font-size:30px;
      }
    }

    &__content {
      font-size: 16px;
      line-height: 28px;
      width: 100%;
      @media (min-width: 600px) {
        font-size:28px;
      }
    }

    &__start {
      width: 100%;
      margin-top: 50px;

      @media (min-width: 600px) {
        margin-top: 30px;
      }
    }
  }
</style>
