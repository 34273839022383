<!-- eslint-disable no-empty -->
<template>
  <SignUpStep
    title="About Me"
    class="signup-about-me"
  >
    <div class="signup-about-me__form">
      <BaseLabel
        label="I am a"
        class="signup-about-me__label"
      />

      <div
        style="width: 100%;"
        class="d-flex flex-xs-column flex-md-column align-center"
      >
        <v-btn-toggle
          id="btnGroup"
          v-model="genderSelect"
          selected-class="dnrSelected"
          variant="outlined"
          group
          style="width: 100%; height: auto;"
          class="d-flex flex-wrap flex-sm-column flex-md-row align-center"
        >
          <v-btn
            v-for=" (item,index) in genders "
            :key="index"
            :value="item"
            rounded
            class="act flex-grow-1 text-wrap"
          >
            {{ item }}
          </v-btn>
          <v-btn
            rounded
            class="act flex-grow-1"
            @click="openModal()"
          >
            Custom
          </v-btn>
        </v-btn-toggle>
      </div>
      <BaseLabel
        label="Seeking a"
        class="signup-about-me__label"
      />
      <div
        style="width: 100%;"
        class="d-flex flex-xs-column flex-md-column align-center"
      >
        <v-btn-toggle
          id="btnGroup"
          v-model="genderSelect1"
          selected-class="dnrSelected"
          style="width: 100%; height: auto;"
          class="d-flex flex-wrap flex-sm-column flex-md-row align-center"
        >
          <v-btn
            v-for=" (item,index) in genders1"
            :key="index"
            :value="item"
            rounded
            class="act flex-grow-1"
          >
            {{ item }}
          </v-btn>
          <v-btn
            rounded
            class="act flex-grow-1"
            @click="openModal2()"
          >
            Custom
          </v-btn>
        </v-btn-toggle>
      </div>
      <BaseLabel
        label="My first name"
        class="signup-about-me__label"
      />
      <BaseInput
        v-model="firstNameModel"
        placeholder=""
        :value="firstNameModel"
      />
      <!-- <BaseLabel
        label="My last name is"
        class="signup-about-me__label"
      />
      <BaseInput
        v-model="lastNameModel"
        placeholder="Enter Last Name"
      /> -->
      <BaseLabel
        label="My age"
        class="signup-about-me__label"
      />
      <BaseInput
        v-model="ageModel"
        :value="ageModel"
        placeholder=""
        :type="'number'"
      />
      <BaseLabel
        label="My height"
        class="signup-about-me__label"
      />
      <div class="signup-about-me__two">
        <BaseSelect2
          v-model="heightFeetModel"
          class="sc"
          :choices="feets"
          :value="heightFeetModel"
          :label="'Feet'"
        />
        <BaseSelect2
          v-model="heightInchesModel"
          class="sc"
          :choices="inches"
          :value="heightInchesModel"
          :label="'Inches'"
        />
      </div>
      <BaseLabel
        label="My zip code"
        class="signup-about-me__label"
      />
      <BaseInput
        v-model="zipCodeModel"
        :value="zipCodeModel"
        placeholder=""
        :type="'text'"
      />
      <BaseError
        v-if="error"
        :message="txterror"
      />
      <BaseButton
        class="signup-about-me__submit"
        text="Next"
        @my-event="submit()"
      />
    </div>
    <v-dialog
      v-model="mod"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title>Select Gender</v-card-title>
        <v-divider />
        <v-card-text style="height: 300px;">
          <v-radio-group
            v-model="gender"
            column
          >
            <v-radio
              label="Agender"
              value="Agender"
            />
            <v-radio
              label="Androgynous"
              value="Androgynous"
            />
            <v-radio
              label="Bigender"
              value="Bigender"
            />
            <v-radio
              label="Gender Fluid"
              value="Gender Fluid"
            />
            <v-radio
              label="Gender Nonconforming"
              value="Gender Nonconforming"
            />
            <v-radio
              label="Intersex"
              value="Intersex"
            />
            <v-radio
              label="Trans Man"
              value="Trans Man"
            />
            <v-radio
              label="Trans Woman"
              value="Trans Woman"
            />
            <v-radio
              label="Non-binary"
              value="Non-binary"
            /> 
            <v-radio
              label="Other"
              value="Other"
            />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color=" darken-1"
            text
            @click="mod = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="saveGender()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="mod2"
      scrollable
      max-width="300px"
    >
      <v-card>
        <v-card-title>Select Gender</v-card-title>
        <v-divider />
        <v-card-text style="height: 300px;">
          <v-radio-group
            v-model="gender1"
            column
          >
            <v-radio
              label="Agender"
              value="Agender"
            />
            <v-radio
              label="Androgynous"
              value="Androgynous"
            />
            <v-radio
              label="Bigender"
              value="Bigender"
            />
            <v-radio
              label="Gender Fluid"
              value="Gender Fluid"
            />
            <v-radio
              label="Gender Nonconforming"
              value="Gender Nonconforming"
            />
            <v-radio
              label="Intersex"
              value="Intersex"
            />
            <v-radio
              label="Trans Man"
              value="Trans Man"
            />
            <v-radio
              label="Trans Woman"
              value="Trans Woman"
            />
            <v-radio
              label="Non-binary"
              value="Non-binary"
            /> 
            <v-radio
              label="Other"
              value="Other"
            />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color=" darken-1"
            text
            @click="mod2 = false"
          >
            Close
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="saveGender2()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </SignUpStep>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import {
  BaseButton,
  BaseError,
  BaseInput,
  BaseLabel,
  BaseSelect2,
} from '@/components/Element';
import SignUpStep from '@/components/Layout/SignUpStep';

export default {
  name: 'SignupAboutMe',
  components: {
    BaseButton,
    BaseError,
    BaseInput,
    BaseLabel,
    BaseSelect2,
    SignUpStep
  },
  data() {
    return {
      error: false,
      text: 'center',
      typeAge:{
        type:'number',
        required: true,
        default: 'number'
      },
      mod: false,
      mod2: false,
      gender: null,
      genderSelect: '',
      genders: ['Man', 'Woman'],

      gender1: null,
      genderSelect1: '',
      genders1: ['Man', 'Woman'],
      textbtn: null,

      feets:[4,5,6],
      inches:[0,1,2,3,4,5,6,7,8,9,10,11],

      txterror: 'All fields are required!'
    };
  },
  computed: {
    ...mapGetters(['isAboutMeValid', 'isAboutMeValidNew', 'user']),
    firstNameModel: {
      get: vm => vm.user.firstName,
      set(value) {
        const arr = value.split(' ');
        for (var i = 0; i < arr.length; i++) {
          arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        const str2 = arr.join(' ');
        this.setFirstName(str2);
      }
    },
    lastNameModel: {
      get: vm => vm.user.lastName,
      set() {
        this.setLastName('');
      }
    },
    ageModel: {
      get: vm => vm.user.age + '',
      set(value) {
        this.setAge(parseInt(value));
      }
    },
    genderModel: {
      get: vm => vm.user.gender,
      set(value) {
        this.setGender(value);
      }
    },
    genderModel2: {
      get: vm => vm.user.gender,
      set(value) {
        this.setGender(value);
      }
    },
    heightFeetModel: {
      get: vm => vm.user.feetHeight + '',
      set(value) {
        const n = parseFloat(value);
        this.setHeightFeet(n.toString());
      }
    },
    heightInchesModel: {
      get: vm => vm.user.inchesHeight + '',
      set(value) {
        const n = parseFloat(value);
        this.setHeightInches(n.toString());
      }
    },
    searchForModel: {
      get: vm => vm.user.searchFor,
      set(value) {
        this.setSearchFor(value);
      }
    },
    zipCodeModel: {
      get: vm => vm.user.zipCode + '',
      set(value) {
        this.setZipCode((value));
      }
    }
  },
  watch: {
    genderSelect: function (newValue, oldValue) {
      this.setGender(newValue);
    },
    genderSelect1: function (newValue, oldValue) {
      this.setSearchFor(newValue);
    }
  },
  created(){
    console.log(this.user);
  },
  methods: {
    ...mapMutations([
      'setAge',
      'setCurrentStep',
      'setFirstName',
      'setLastName',
      'setGender',
      'setHeightFeet',
      'setHeightInches',
      'setSearchFor',
      'setZipCode'
    ]),
    isNumber: (event) => {
      let keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    async submit() {
      console.log(this.isAboutMeValidNew);
      if(this.isAboutMeValidNew == false){
        this.txterror = 'All fields are required!';
        this.error = true;
        return;
      }
      if(this.isAboutMeValidNew !== true){
        this.txterror = 'Field Required: \n' + this.isAboutMeValidNew;
        this.error = true;
        return;
      }
      if(this.zipCodeModel && this.zipCodeModel.length !== 5){
        this.txterror = 'Zip code must be 5 characters'
        this.error = true;
        return;
      }else{
        this.txterror = 'All fields are required!'
      }

      if (!this.isAboutMeValid) {
        this.txterror = 'All fields are required!'
        this.error = true;
        return;
      }
      this.error = false;
      //this.setCurrentStep(11);
      this.$router.push({
        name: 'viewProfileNewUsers'
      });
      this.setCurrentStep(0);
    },
    async openModal(){
      this.mod = true;
    },
    async openModal2(){
      this.mod2 = true;
    },
    async saveGender(){
      this.genders = ['Man', 'Woman'];
      this.genderSelect = this.gender;
      this.genders.push(this.gender);
      this.mod = false;
      if(this.genders.length);
    },
    async saveGender2(){
      this.genders1 = ['Man', 'Woman'];
      this.genderSelect1 = this.gender1;
      this.genders1.push(this.gender1);
      this.mod2 = false;
      if(this.genders1.length);
    }
  }
};
</script>

<style lang="scss" scoped>
.signup-about-me {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 450px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: calc(100% - 40px);
      align-items: flex-start;
    }
  }
  &__label {
    margin-top: 30px;

    @media (max-width: 500px) {
      font-size: 22px;
    }    
  }

  .lb{
    width: fit-content;
    color: black;
    background-color: red;
    border-radius: 5px !important;
    border-width: 1px !important;
    border-color: rgb(213, 105, 105) !important;
    margin-right: 15px;
    padding-left: 25px;
    padding-right: 25px;
  }

  &__two {
    display: flex;
    justify-content: space-between;
    width: 450px;
    padding-top: 6px;
    //background: red;
    .base-input {
      width: calc(50% - 10px);
    }

    @media (max-width: 500px) {
      width: 100%;
    }

    .secu{
      background: rgb(255, 255, 255);
      width: 50%;
      height: fit-content;
      border-color: rgb(49, 49, 49);
      border-style: solid;
      border-width: 1px;
      padding: 10px;
      border-radius: 10px;
    }
  }

  &__submit {
    margin-top: 20px;
  }


  .v-btn{
    border-style: solid;
  }
  #btnGroup .dnrSelected {
    background-color: #009933 !important;
    color: white !important;
    border-radius: 4px !important;
    border-color: #009933  !important;
  }

  #btnGroup .act{
    width: 70px;
    color: black;
    border-radius: 4px;
    border-width: 1px;
    border-color: rgb(190, 190, 190);
    background: white;
    margin-right: 10px;
    padding-left: 25px;
    padding-right: 25px;
    padding: 13px;
  }
}
</style>
