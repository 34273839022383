<template>
  <div class="base-header">
    <v-container class="base-header__container lighten-5">
      <v-row>
        <v-col
          cols="3"
          class="base-header__col"
          :class="{ active: currentRouteName == 'viewProfile' }"
        > 
          <router-link :to="{ name: 'viewProfile' }">
            <img
              v-if="currentRouteName != 'viewProfile' && currentRouteName != 'viewProfileNewUsers'"
              class="base-header__avatar"
              src="../../assets/topbar/star1.png"
              alt=""
            >
            <img
              v-if="currentRouteName == 'viewProfile' || currentRouteName == 'viewProfileNewUsers'"
              class="base-header__avatar"
              src="../../assets/topbar/star2.png"
              alt=""
            >
          </router-link>
          <p class="text-center txt">
            Discover
          </p>
        </v-col>
        <v-col
          cols="3"
          class="base-header__col"
          :class="{ active: currentRouteName == 'editProfile' }"
        >
          <router-link :to="{ name: 'editProfile' }">
            <img
              v-if="currentRouteName != 'editProfile'"
              class="base-header__avatar" 
              src="../../assets/topbar/user.png"
              alt=""
            >
            <img
              v-if="currentRouteName == 'editProfile'"
              class="base-header__avatar"
              src="../../assets/topbar/green_user.png"
              alt=""
            >
          </router-link>
          <p class="text-center txt">
            Profile
          </p>
        </v-col>
        <v-col
          cols="3"
          class="base-header__col"
          :class="{ active: currentRouteName == 'chat' }"
        >
          <router-link :to="{ name: 'chat' }">
            <img
              v-if="currentRouteName != 'chat'"
              class="base-header__avatar i"
              src="../../assets/topbar/message.png"
              alt=""
            >
            <img
              v-if="currentRouteName == 'chat'"
              class="base-header__avatar i"
              src="../../assets/topbar/green_message.png"
              alt=""
            >
          </router-link>
          <p class="text-center txt">
            Matches
          </p>
        </v-col>
        <v-col
          cols="3"
          class="base-header__col"
          :class="{ active: currentRouteName == 'settings' }"
        >
          <router-link :to="{ name: 'settings' }">
            <img
              v-if="currentRouteName != 'settings'"
              class="base-header__avatar"
              src="../../assets/topbar/settings.png"
              alt=""
            >
            <img
              v-if="currentRouteName == 'settings'"
              class="base-header__avatar"
              src="../../assets/topbar/green_settings.png"
              alt=""
            >
          </router-link>
          <p class="text-center txt">
            Settings
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { useRoute } from 'vue-router'

export default {
  name: 'TopBar',
  computed: {
    currentRouteName() {
      const route = useRoute()
      let name = route.name;
      return name;
    }
  }
};
</script>

<style lang="scss" scoped>
.base-header {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.03);
  height: 100px;
  text-align: center;

  &__container {
    padding: 0;

    .row {
      margin: -7px;
    }
  }

  &__col {
    padding: 0;
  }

  .txt{
    font-size: 13px;
    font-weight: 700;
    line-height: 24px;
    color: rgb(84, 84, 84);

    @media (min-width: 600px) {
      font-size: 20px;
    }
  }

  img {
    padding: 15px 15px 5px 15px;
  }

  .i {
    margin-top: 5px;
    //padding: 15px 15px 11px 15px;
  }

  .active img {
    background: rgba(232, 249, 219, 0.5);
  }
}
</style>
