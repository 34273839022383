<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/require-default-prop -->
<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="photos-box"
    :class="{ 'photos-box--filled': image[index] !== undefined }"
  >
    <label
      class="photos-box__add"
      :for="`photo_${index}`"
      :style="image[index] !== undefined ? { border: 0 } : {}"
    >
      <button
        v-show="image[index] !== undefined || photos[index] !== undefined"
        class="photos-box__delete"
        @click="deletePhoto(index, image[index], photos[index])"
      >
        <v-icon color="red">
          mdi-delete
        </v-icon>
      </button>

      <img
        v-if="image[index] === undefined && photos[index] === undefined"
        src="../../assets/plus.svg"
        alt="Add"
      >
      <img
        v-else-if="image[index] === undefined && photos[index]"
        class="photos-box__image"
        :src="`https://oss.tryfilters.com/${photos[index]}`"
        alt="..."
      >
      <img
        v-else
        class="photos-box__image"
        :src="image[index]"
        alt="..."
      >
      <input
        v-show="false"
        :id="`photo_${index}`"
        type="file"
        @change="onFileChanged($event, index)"
      >
    </label>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
var vm = this;
export default {
  name: 'PhotosBox',
  props: {
    index: {
      type: Number,
      required: true
    },
    files: {
      type: Array,
      required: true
    },
    photos: {
      type: Array
    },
    deletedPhotos: {
      type: Array
    }
  },
  data() {
    return {
      image: []
    };
  },
  methods: {
    ...mapMutations(['addPhoto']),
    deletePhoto(index, image, photo) {
      if (image) {
        this.image = this.image.splice(index, 1);
        //vm.$set(, index, undefined);
        // eslint-disable-next-line vue/no-mutating-props
        this.files[index] = undefined;
      }
      if (photo) {
        // eslint-disable-next-line vue/no-mutating-props
        this.deletedPhotos[index] = this.photos[index];
        //vm.$set(this.deletedPhotos, index, this.photos[index]);
        // eslint-disable-next-line vue/no-mutating-props
        this.photos = this.photos.splice(index, 1, undefined);
      }
    },
    onFileChanged(event, index) {
      
      const files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      }
      const reader = new FileReader();
      reader.onload = e => {
        if (this.photos[index]) {
          vm.$set(this.deletedPhotos, index, this.photos[index]);
          // eslint-disable-next-line vue/no-mutating-props
          this.photos = this.photos.splice(index, 1, undefined);
        }
        this.image[index] = e.target.result;
        // eslint-disable-next-line vue/no-mutating-props
        this.files[index] = files[0];
        //vm.$set(this.image, index, e.target.result)
        //vm.$set(this.files, index, files[0])
      }
      reader.readAsDataURL(files[0]);
    }
  }
};
</script>

<style lang="scss" scoped>
.photos-box {
  position: relative;
  border: 2px dotted #000;
  border-radius: 8px;
  width: 225px;
  height: 225px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media (max-width: 500px) {
    width: 150px;
    height: 150px;
  }

  &--filled {
    border: 2px solid #000000;
  }

  &:hover {
    border-style: solid;
  }

  &__image {
    width: 221px;
    height: 221px;
    border-radius: 28px;
    object-fit: cover;
    @media (max-width: 500px) {
      width: 148px;
      height: 148px;
    }
  }

  &__add {
    border: 10px solid #009933;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  &__delete {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
}
</style>
