<template>
  <div class="error-message">
    <img
      class="error-message__icon"
      src="./assets/error.svg"
      alt="..."
    >
    <p class="error-message__note">
      Email already exists. <a href="/login">Try logging in</a>
    </p>
  </div>
</template>

<script>
  export default {
    name: 'BaseError',
    props: {
      message: {
        type: String,
        required: true,
      },
    },
  };
</script>

<style lang="scss" scoped>
  .error-message {
    display: flex;
    align-items: center;
    width: 450px;
    margin-bottom: 15px;

    @media (max-width: 500px) {
      width: 100%;
    }

    &__icon {
      margin-right: 10px;
    }

    &__note {
      font-size: 16px;
      color: darkred;
      margin-bottom: 0;
    }
  }
</style>
