<template>
  <div class="settings">
    <Load v-if="stateLoad" />
    <h3 class="settings__title">
      Filters
    </h3>
    <div class="settings__container">
      <div class="settings__basic">
        <div class="settings__filter ">
          <div class="ddc">
            <p class="settings__filter-item">
              My Zip Code
            </p>
            <input
              v-model="zipCode"
              class="settings__filter-input"
              type="text"
              @change="onChangeZipCode"
            >
          </div>
          
          <v-dialog
            v-model="distanceModal"
            fullscreen
            :scrim="false"
            transition="dialog-bottom-transition"
          >
            <template #activator="{ props }">
              <div
                class="settings__filter with-underline ccd"
                v-bind="props"
              >
                <p class="settings__filter-item">
                  Maximum Distance
                </p>
                <p class="settings__filter-value">
                  {{ labelDistance }}
                </p>
                <v-icon
                  icon="mdi-chevron-right"
                  class="settings__icon"
                />
              </div>
            </template>
            <v-card>
              <v-container>
                <div class="maxmd">
                  <v-card-title class="settings__modal-title">
                    <span class="text-h5">Maximum Distance</span>
                  </v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="maxDistance"
                          label=""
                          suffix="miles"
                          type="number"
                          required
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="cancelDistance"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="confirmDistance"
                    >
                      Save
                    </v-btn>
                  </v-card-actions>
                </div>
              </v-container>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="ageModal"
            fullscreen
            :scrim="false"
            transition="dialog-bottom-transition"
          >
            <template #activator="{ props }">
              <div
                class="settings__filter with-underline"
                v-bind="props"
              >
                <p class="settings__filter-item">
                  Age Range
                </p>
                <p class="settings__filter-value">
                  {{ labelAgeRange }}
                </p>
                <v-icon
                  icon="mdi-chevron-right"
                  class="settings__icon"
                />
              </div>
            </template>
            <v-card>
              <v-container>
                <v-card-title class="settings__modal-title">
                  <span class="text-h5">Age Range</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="minAgeRange"
                        class="scd"
                        :items="minAge"
                        label="Minimum Age"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="maxAgeRange"
                        class="scd"
                        :items="maxAge"
                        label="Maximum Age"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="cancelAgeRange"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="confirmAgeRange"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>
        
          <v-dialog
            v-model="heightModal"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <template #activator="{ props }">
              <div
                class="settings__filter with-underline"
                v-bind="props"
              >
                <p class="settings__filter-item">
                  Height Range
                </p>
                <p class="settings__filter-value">
                  {{ labelHeight }}
                </p>
                <v-icon
                  icon="mdi-chevron-right"
                  class="settings__icon"
                />
              </div>
            </template>
            <v-card>
              <v-container>
                <v-card-title class="settings__modal-title">
                  <span class="text-h5">Height Range</span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="minHeightRange"
                        class="scd"
                        :items="setHe"
                        label="Minimum Height"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      <v-select
                        v-model="maxHeightRange"
                        class="scd"
                        :items="setHe"
                        label="Maximum Height"
                        required
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="cancelHeight"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="confirmHeight"
                  >
                    Save
                  </v-btn>
                </v-card-actions>
              </v-container>
            </v-card>
          </v-dialog>
        </div>
      </div>
      <div class="btns">
        <BaseButton
          style="align-self: flex-end;"
          class="btn"
          text="Give Feedback"
          @my-event="openFeedBack()"
        />
        <BaseButton
          style="align-self: flex-end;"
          class="btn"
          text="Logout"
          @my-event="onLogout()"
        />
        <BaseButton
          v-if="!user.temporal"
          style=" display: flex; align-self: flex-end;"
          class="btn2"
          text="Delete My Account"
          @my-event="openDeleteAccount()"
        />

        <!--<BaseButton
          v-if="user.temporal"
          style=" display: flex; align-self: flex-end;"
          class="btn2"
          text="Update My Account"
          @my-event="updateAccount()"
        /> -->
      </div>

      <v-dialog
        v-model="modalDelete"
        width="250px"
        style="overflow-x: hidden !important;"
      >
        <v-card>
          <v-card-title
            class="th"
            style="hyphens: auto; overflow-wrap: break-word;"
          >
            Are you sure?
          </v-card-title>
          <v-card-actions>
            <v-btn
              class="th"
              color="danger"
              text
              @click="modalDelete = false"
            >
              Cancel
            </v-btn>
            <v-btn
              class="th"
              color="primary"
              text
              @click="accountDelete"
            >
              Yes, Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="dialogFeedBack"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        class="ccl"
      > 
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-btn
              icon
              dark
              @click="closeFeedBack()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title style="font-variant: bold;">
              FeedBack
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider />
          <div
            style="margin-right: 3%;margin-left: 3%; background: red;"
          >
            <v-list lines="three">
              <v-list-item>
                <div
                  class="base-text-area"
                >
                  <textarea
                    v-model="text"
                    class="signup-bio__textarea"
                    placeholder="Enter FeedBack"
                  />
                  <div
                    v-if="limit !== null"
                    class="base-text-area__limit"
                  >
                    {{ text.length }}/{{ limit }}
                  </div>
                </div>

  
                <BaseButton
                  style="width: 100%;"
                  class="btn"
                  text="Send Feedback"
                  @my-event="sendFeedBack()"
                />
              </v-list-item>
            </v-list>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { BaseButton} from '@/components/Element';
import { Load } from '@/components/Element';
import { CometChat } from '@cometchat-pro/chat';

export default {
  name: 'Settings',
  components: {
    BaseButton,
    Load
  },
  data() {
    return {
      ageModal: false,
      distanceModal: false,
      heightModal: false,
      interested: '',
      zipCode: '',
      minAgeRange: '',
      maxAgeRange: '',
      maxDistance: '',
      minHeightRange: '',
      maxHeightRange: '',
      modalDelete: false,
      stateLoad: false,
      dialogFeedBack: false,
      limit:1000,
      text:'',
      dialogPhoto: false,
    };
  },
  computed: {
    ...mapGetters(['user', 'filter', 'feedBack']),
    txt: {
      get: vm => vm.feedBack,
      set(v) {
        console.log('test ', v )
        this.setFeedBack(v);
      }
    },
    maxAge() {
      let array = [];
      for (let i = 18; i <= 90; i++) {
        array.push(i);
      }
      return array;
    },
    minAge() {
      let array = [];
      for (let i = 18; i <= 90; i++) {
        array.push(i);
      }
      return array;
    },
    labelAgeRange() {
      return this.minAgeRange && this.maxAgeRange
        ? this.minAgeRange + ' - ' + this.maxAgeRange
        : '';
    },
    labelDistance() {
      return this.maxDistance > 0 ? this.maxDistance + ' miles' : 'Any';
    },
    labelHeight() {
      /*let labelText = 'ALL TO ALL';
      if(this.minHeightRange == '0'){
        labelText = 'ALL TO ' + this.maxHeightRange;
      }
      if(this.maxHeightRangeHeightRange == '0'){
        labelText = this.minHeightRange + ' TO ALL';
      }
      if(this.minHeightRange == '0' && this.maxHeightRangeHeightRange == '0'){
        labelText = 'ALL TO ALL';
      }*/
      return (this.minHeightRange != '0' && this.maxHeightRange !== '0') ? this.minHeightRange +' to ' + this.maxHeightRange : 'ALL TO ALL';
    },
    setHe(){
      let array = [];
      for (let index = 4; index <= 7; index++){
        for(let j = 0; j < 12; j++){
          if(index == 7 && j > 0) break;
          array.push(index+ '’ '+ j+ '”');
        }  
      }
      return array;
    }
  },
  created() {
    this.interested = this.filter.interested;
    this.zipCode = this.filter.zipCode;
    this.minAgeRange = this.filter.minAgeRange;
    this.maxAgeRange = this.filter.maxAgeRange;
    this.maxDistance = this.filter.maxDistance;
    this.minHeightRange = this.filter.minHeightRange;
    this.maxHeightRange = this.filter.maxHeightRange;
    if(this.filter?.maxHeightRange === '0'){
      this.maxHeightRange = 'ALL';
    }
    if(this.filter?.minHeightRange === '0'){
      this.minHeightRange = 'ALL';
    }
    
    this.init();
  },
  methods: {
    ...mapActions(['saveSettings', 'logout','logoutTry', 'deleteAccount', 'sendFeedBackUser']),
    ...mapMutations([
      'setInterested',
      'setFilterZipCode',
      'setAgeRange',
      'setDistance',
      'setHeightRange', 
      'setFeedBack'
    ]),
    async init(){
      console.log(this.user);
      if(!this.user.photos || this.user.photos.length <= 0){
        setTimeout(() => {
          this.dialogPhoto = true;
        }, 30000);
      } 
    },
    onChangeInterested() {
      this.setInterested(this.interested);
      this.submitSettings();
    },
    onChangeZipCode() {
      this.setFilterZipCode(this.zipCode);
      this.submitSettings();
    },
    confirmAgeRange() {
      this.setAgeRange({ min: this.minAgeRange, max: this.maxAgeRange });
      this.ageModal = false;
      this.submitSettings();
    },
    confirmDistance() {
      this.setDistance(this.maxDistance);
      this.distanceModal = false;
      this.submitSettings();
    },
    confirmHeight() {
      if(this.minHeightRange === 'ALL' && this.maxHeightRange === 'ALL'){
        this.cancelHeight();
        return;
      }
      if(this.minHeightRange === 'ALL'){
        this.minHeightRange = '0';
      }

      if(this.maxHeightRange === 'ALL'){
        this.maxHeightRange = '0';
      }
      this.setHeightRange({
        min: this.minHeightRange,
        max: this.maxHeightRange
      });
      this.heightModal = false;
      this.submitSettings();
    },
    cancelAgeRange() {
      this.maxAgeRange = this.filter.maxAgeRange;
      this.minAgeRange = this.filter.minAgeRange;
      this.ageModal = false;
    },
    cancelDistance() {
      this.maxDistance = this.filter.maxDistance;
      this.distanceModal = false;
    },
    cancelHeight() {
      this.maxHeightRange = this.filter.maxHeightRange;
      this.minHeightRange = this.filter.minHeightRange;
      this.heightModal = false;
    },
    async submitSettings() {
      try {
        const res = await this.saveSettings();
        console.log(this.filter);
        console.log(this.maxHeightRange);
        console.log(this.minHeightRange);
        if(this.maxHeightRange === '0'){
          this.maxHeightRange = 'ALL';
        }
        if(this.minHeightRange === '0'){
          this.minHeightRange = 'ALL';
        }
      } catch (error) {
        console.log(error);
      }
    },
    async onLogout(){
      try {
        CometChat.logout().then(async (res) => {
          if(!this.user?.temporal){
            await this.logout()
          }else{
            await this.logoutTry()
          }
          this.$router.push({
            name: 'login',
          })
          return;
        }).catch(err => {
          console.log(err);
          return;
        })
      } catch (error) {
        console.log(error);
        return;
      }
    },
    async openDeleteAccount(){
      this.modalDelete = true;
    },
    async updateAccount(){
      try {
        console.log('update');
      } catch (error) {
        console.log(error);
        return;
      }
    },
    async openFeedBack(){
      this.dialogFeedBack = true;
      this.setFeedBack('');
      this.text = ''
    },
    async closeFeedBack(){
      this.dialogFeedBack = false;
      this.setFeedBack('');
      this.text = ''
    },
    async sendFeedBack(){
      console.log(this.feedBack)
      this.setFeedBack(this.text);
      if(this.text === '' || this.text === null){
        return;
      }
      this.stateLoad = true;
      const resp = await this.sendFeedBackUser();
      if(!resp){
        this.stateLoad = false;
        alert('Try again');
        return;
      }
      this.stateLoad = false;
      this.closeFeedBack();
    },
    async accountDelete(){
      this.stateLoad = true;
      const res = await this.deleteAccount();
      if(res){
        this.modalDelete = false;
        this.onLogout();
      }else{
        this.modalDelete = false;
      }
    },
    changePage(){
      this.$router.push({
        name: 'editProfile'
      });
      this.dialogPhoto = false;
    },
  }
};
</script>

<style lang="scss" scoped>

.base-text-area {
  width: 100%;
  @media (max-width: 500px) {
    width: 100%;
  }
  textarea {
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    border: 1.5px solid #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__limit {
    text-align: right;
    margin-top: 7px;
  }
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}
.settings {

  @media (min-width: 600px) {
    margin-right: 22%;
    margin-left: 22%;
  }
    
  .with-underline {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
  }

  .ccd{
    align-items: center;
  }

  .ddc{
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    padding-bottom: 15px;
  }

  .maxmd{
    background: red !important;
  }

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: #000;
    letter-spacing: 0.07em;
    text-align: center;
    margin: 30px 0;

    @media (max-width: 500px) {
      margin-top: 34px;
    }
  }

  &__container {
    padding: 1rem;
  }

  &__filter {
    padding: 1rem 0;
    position: relative;
    
  }

  &__basic {
    margin-bottom: 2rem;
  }

  &__subtitle {
    color: rgba(0, 0, 0, 0.4);
    font-weight: 600;
    font-style: normal;
    line-height: 24px;
  }

  &__filter-item {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: #000;
    margin: 0;
    
  }

  &__filter-input {
    width: 100%;
    outline-width: 0;
  }

  &__filter-value {
    margin: 0;
    color: #000;
  }

  &__icon {
    color: #000;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 45px;
  }

  .btn{
    margin-top: 10px;
    width: fit-content;
    font-size: 19px;
    margin-right: 20px;

    @media (max-width: 500px) {
      margin-right: 0px;
    }
  }
  .btn2{
    align-self: flex-end;
    margin-top: 10px;
    width: fit-content;
    font-size: 19px;

    @media (max-width: 500px) {
      margin-top: 0px;
    }
  }

  .btns{
    width: 100%; 
    display: flex;
    flex-direction: row;
    flex-wrap: row wrap;

    @media (max-width: 1148px) {
      flex-direction: column;
    }
  }

  .contentModal{
    //background-color: red;
    width: 100%;
    @media (min-width: 600px) {
      margin-right: 22%;
      margin-left: 22%;
    }
  }

}

.th{
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
.base_button {
  align-self: center;
  padding: 12px 28px;
  width: 60%;
  //height: 62px;
  background: #009933;
  border: 1.5px solid #009933;
  border-radius: 8px;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  outline-width: 0;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    //width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>