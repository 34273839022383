<template>
  <button
    :disabled="disabled"
    class="base-button"
    :style="{ '--width': width }"
    @click="$emit('my-event')"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    disabled: {
      type: Boolean
    },
    text: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: '452px'
    }
  }
};
</script>

<style lang="scss" scoped>
.base-button {
  padding: 12px 28px;
  width: 450px;
  height: 62px;
  background: #009933;
  border: 1.5px solid #009933;
  border-radius: 8px;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  outline-width: 0;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
}
</style>
