<template>
  <SignUpStep
    title="Reset Password"
    class="login"
  >
    <BaseError
      v-if="error !== null && titleName == null"
      style="display: flex; justify-content: center; width: 100%;"
      :message="error"
    />
    <div
      v-if="titleName !== null"
      class="login__form"
    >
      <BaseLabel
        :label="titleName"
        class="login__label"
      />
      <v-alert
        v-if="emailSend"
        type="success"
        dark
        dense
        icon="mdi-star"
        prominent
        shaped
      >
        Your password has been successfully reset.
      </v-alert>
      <BaseError
        v-if="error !== null"
        :message="error"
      />
      <BaseLabel
        v-if="!emailSend"
        label="Password"
        class="login__label"
      />
      <BaseInput
        v-if="!emailSend"
        v-model="password"
        :value="password"
        placeholder="Enter your Password "
        type="password"
        class="login__input"
      />

      <BaseLabel
        v-if="!emailSend"
        label="Confirm Password"
        class="login__label"
      />
      <BaseInput
        v-if="!emailSend"
        v-model="password2"
        :value="password2"
        placeholder="Confirm Password "
        type="password"
        class="login__input"
      />

      <BaseButton
        v-if="!emailSend"
        class="login__submit"
        text="Reset Password"
        @my-event="submit"
      />
    </div>
    <Loader v-if="loadStatus" />
  </SignUpStep>
</template>
<script>
import {
  BaseError,
  BaseButton,
  BaseInput,
  BaseLabel,
  Loader
} from '@/components/Element';
import SignUpStep from '@/components/Layout/SignUpStep';
import { mapActions } from 'vuex';

export default {
  name: 'ForgotPassword',
  components: {
    BaseError,
    BaseButton,
    BaseInput,
    BaseLabel,
    SignUpStep,
    Loader
  },
  data(){
    return {
      email: '',
      password: '',
      password2: '',
      titleName: 'Hi !',
      error: null,
      emailSend: false,
      loadStatus: false
    };
  },
  created(){
    this.validateUid(this.$route.params.id);
  },
  methods: {
    ...mapActions(['ForgotPassword', 'ValidateEmailReset', 'ResetPassword']),
    async submit(){
      this.emailSend = false;
      this.error = null;
      if (!this.password) {
        this.error = 'Password is required.';
        return;
      }

      if(this.password.length < 8) {
        this.error = 'Password must be at least 8 characters long.';
        return;
      }

      if (!this.password2) {
        this.error = 'Repet Password is required.';
        return;
      }

      if(this.password !== this.password2){
        this.error = 'The password does not match.';
        return;
      }

      this.loadStatus = true;
      const res = await this.ResetPassword({
        email: this.$route.params.id,
        password: this.password,
      });
      if (res.valid) {
        this.emailSend = true;
        this.loadStatus = false;
        this.password = '';
        this.password2 = '';
      } else {
        this.emailSend = false;
        this.loadStatus = false;
        if(res.ms){
          this.error = 'Login Failed, Try again.  ' + res.ms;
        }
      }

    },
    async validateUid(id){
      this.loadStatus = true;
      try {
        const res = await this.ValidateEmailReset({
          email: id,
        });
        if (res.valid) {
          this.titleName = 'Hi ' + res.data.userName + '!';
          this.loadStatus = false;
        } else {
          this.loadStatus = false;
          this.titleName = null;
          if(res.ms){
            this.error = 'Try again.  ' + res.ms;
          }
        } 
      } catch (error) {
        console.log(error);
        this.loadStatus = false;
      }
    }
  }
}
</script> 

<style lang="scss" scoped>
.login {
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    width: 450px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: calc(100% - 40px);
      padding-top: 30px;
    }
  }

  &__label {
    margin-top: 30px;

    &:not(:first-child) {
      margin-top: 60px;
    }

    @media (max-width: 500px) {
      margin: 0;
      font-size: 24px;
      text-align: left;
    }
  }

  &__submit {
    margin-top: 40px;
  }

  .info-message {
    margin-left: 50px;
  }
}
</style>