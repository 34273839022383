<template>
  <div id="chats">
    <Load v-if="loadingStatus" />
    <div class="content-wrapper">
      <div class="content">
        <section class="msger">
          <header class="msger-header">
            <div class="msger-header-title">
              <div class="msger__avatar">
                <v-avatar
                  class="avt"
                  size="90"
                  @click="viewProfile()"
                >
                  <img
                    style="object-fit: contain;"
                    :src="user.avatar"
                    alt="USER NAME"
                  >
                </v-avatar>
              </div>
              <div
                class="msger__avatar__details"
                style="margin-left: 10px; display: flex; flex-direction: column;"
              >
                <span>{{ user.name }}</span>
                <small class="txtstatus">{{ user.status }}</small>
              </div>
            </div>
            <div>
              <v-switch
                v-model="switch1"
                label="Block"
              />
            </div>
            <v-dialog
              v-model="switch1"
              width="600"
              style="border-radius: 50px"
            >
              <v-card style="display:flex; flex-direction: column">
                <v-card-title
                  class="th"
                  style="hyphens: auto; overflow-wrap: break-word;"
                >
                  Do you want to block this person?
                </v-card-title>
                <v-icon
                  right
                  large
                  color="green"
                  style="font-size:40px; align-self: center"
                >
                  mdi-account-remove
                </v-icon>
                <v-card-actions>
                  <v-btn
                    class="th"
                    color="danger"
                    text
                    @click="switch1 = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    class="th"
                    color="primary"
                    text
                    @click="act()"
                  >
                    Yes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </header>

          <main
            id="cs"
            ref="container"
            class="msger-chat"
          >
            <div
              v-for="message in messages"
              :key="message.id"
              class="msg-list"
            >
              <div v-if="typeof message.text != 'undefined' ">
                <div
                  v-if="message.receiverId !== user.uid"
                  class="msg left-msg"
                >
                  <div class="msg--wrapper">
                    <div class="bubble--wrapper">
                      <v-avatar
                        class="avt"
                        size="90"
                      >
                        <img
                          style="object-fit: contain;"
                          :src="user.avatar"
                          alt="USER NAME"
                        >
                      </v-avatar>
                      <div class="msg-bubble">
                        <div class="msg-info">
                          <div class="msg-info-name">
                            {{ user.name }}
                          </div>
                        </div>

                        <div class="msg-text">
                          {{ message.text }}
                        </div>
                      </div>
                    </div>

                    <div class="msg-info-time">
                      {{ toReadableString(message.sentAt) }}
                    </div>
                  </div>
                </div>

                <div
                  v-else
                  class="msg right-msg"
                >
                  <div class="msg--wrapper">
                    <div class="bubble--wrapper">
                      <v-avatar
                        class="avt"
                        size="90"
                      >
                        <img
                          style="object-fit: contain;"
                          :src="message.sender.avatar"
                          alt="USER NAME"
                        >
                      </v-avatar>
                      <div class="msg-bubble">
                        <div class="msg-info">
                          <div class="msg-info-name">
                            {{ message.sender.name }}
                          </div>
                        </div>

                        <div class="msg-text">
                          {{ message.text }}
                        </div>
                      </div>
                    </div>
                    <div class="msg-info-time">
                      <img
                        v-if="
                          message.sentAt &&
                            !message.deliveredAt &&
                            !message.readAt
                        "
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAAXNSR0IArs4c6QAAARdJREFUOBFjYEAGIiIiUQg+kBeB4EFZYmJiVnDB////M8I5WBmSkpImIAkmmCxQy0MYmwFogxGcQxYDaEIaTCPcKSA3/vv3T56JiYn11atXi2AKBopGdiQLuiNERUU9WVlZD8HEwSElLi4eBZTgcXBwYAGGmOyzZ89uwBTAvQk19p+AgMDiO3fu/IQpQKGBptigCNCFw0ysLSAPfvv2zZ+bm1vo69evj2H64OkFJoCNVlFR4bty5UoCUI6Xj4/vLDY1YDEFBQUOkE3ICiQkJBRAIQhMLAHY0jE8mEGagPFh8ffvXz0g8xMjI+MWFhYWjd+/fxsB2ddev359BNlgGBvFAJgg0DZrYNLUBvHZ2NgOIUcsTM3goQFXzEj1uzh+EwAAAABJRU5ErkJggg=="
                        alt="time"
                        class="message__timestamp__img"
                      >

                      <img
                        v-else-if="
                          message.sentAt &&
                            message.deliveredAt &&
                            !message.readAt
                        "
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAAXNSR0IArs4c6QAAAWNJREFUOBFjYEAGEhISosh8BgaQCCNMSFJS0gTGZhARETECYbgAMgOuRUxMzAokwQhUGgGkmYD435s3b1aABBn+///PCMJgDskE0Mg0kCYYDTYGZOG/f//kmZiYWIFGr2NkZAx69erVIpJNp5IGmONgNIpfRUVFPVlZWe//+fNHnoWF5eGzZ89uMImLi0cBJXgcHBxYgD6Qtba2vg3yEUgS5CiwCVDj/gkICCz++PFjND8//9I7d+78RHE10BQbkACMRpGkKYeZkOkgz3379s2fm5tb6OvXr4/R+SjBhG6YiooK34cPH8KAcfyLj49vJVCeHRgIocC4/g3iwwNCQUGBA2QysgHAFK0ACj1gQgkApT0pKSk5ZD5MLdgFwLiw+Pv3rx5Q8BPQ9C3ASNL4/fu3EZB97fXr10eAIWsINMQUxodpBtEoXgDaZg2MRG2QBBsb2yFQZAINdwEargTjg+QGFwAAd+eJSUtUxzMAAAAASUVORK5CYII="
                        alt="time"
                        class="message__timestamp__img"
                      >

                      <img
                        v-else
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAFo9M/3AAAAAXNSR0IArs4c6QAAAWdJREFUOBHNUrtKA1EQPXeNRUDxgVqLQj7AjWjAwh8QKyvbuIIgVhYWNhYWdhFBkugPpLOwt7CwSEQLrTQI1roB44Os7nhm4YZdCyWg4MDsmTOzM3dm7gXiMlUWN84B9RjrcouyBQOJOMmmqg0mMJ5S0IjhrzfELmpQWzYZdUJEjGpEOv6wZNSKxagMScEYzDHU63RjIgxwzvOGO67+Swm2OYupeN1sSY650DzxSFFjxi3JXcpgxunDQ8tHc2wA6bqPV06h24NT88zoe4h7Bv10D4bqDTwqajAh2aLsqcNiIvin5McbzVUkHTRwwms642BrX/m3BbjLcbZ/RX3i4JnWC/pDwTWLNZWfLhrf0flyBzKoldW2ki3LPG19jBdVDyNvz5j+CFFn8qVyTdZ/ow64uR2+sXXy25SDWa7do62Pfp9tr0yWZIMnb1tObEtiBN7wLk9Y1ahxkK8umUOOUSFdsLyd+W+MTyDvnh60pF/jAAAAAElFTkSuQmCC"
                        alt="time"
                        class="message__timestamp__img"
                      >

                      {{ toReadableString(message.sentAt) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="spacer" />
          </main>
          
          <form
            class="msger-inputarea"
            @submit.prevent="sendMessage()"
          >
            <input
              v-model.trim="message"
              type="text"
              class="msger-input"
              placeholder="Enter your message..."
            >
            <button
              type="submit"
              class="msger-send-btn"
            >
              <v-icon
                right
                dark
                style="font-size: 22px"
              >
                mdi-play
              </v-icon>
            </button>
          </form>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { CometChat } from '@cometchat-pro/chat';
import { mapActions, mapGetters } from 'vuex';
import { Load } from '@/components/Element';

export default {
  name: 'Chats',
  components: {
    Load
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      messages: [],
      message: '',
      user: {},
      switch1: false,
    };
  },
  created() {
    this.getMessages();
    this.getUser();
    this.listenForMessage();
  },
  unmounted() {
    CometChat.removeMessageListener(this.uid);
    let listenerID = 'UNIQUE_LISTENER_ID';
    CometChat.removeUserListener(listenerID);
  },
  // eslint-disable-next-line vue/order-in-components
  computed:{
    ...mapGetters(['loadingStatus']),
  },
  updated() {
    this.scrollToEnd()
  },
  methods: {
    ...mapActions(['deleteMyFriend', 'getUserLike']),
    getUser() {
      const uid = this.uid;
      CometChat.getUser(uid)
        .then((user) =>{
          this.user = user;
        })
        .catch((error) => {});
    },
    getMessages() {
      const limit = 50;
      const messagesRequest = new CometChat.MessagesRequestBuilder()
        .setLimit(limit)
        .setUID(this.uid)
        .build();
      messagesRequest
        .fetchPrevious()
        .then((messages) => {
          messages.map((message, i) => {
            if (!message.readAt) {
              const messageId = message .id;
              const receiverId = message.receiverId;
              const receiverType = 'user';
              const senderID = message.sender.uid;
              if (i + 1 === messages.length && receiverId === this.uid)
                CometChat.markAsRead(messageId, receiverId, receiverType, senderID);
            }
          });
          this.messages = messages;
        })
        .catch((error) => {});
    },
    sendMessage() {
      const receiverID = this.uid;
      const messageText = this.message;
      const receiverType = CometChat.RECEIVER_TYPE.USER;
      const textMessage = new CometChat.TextMessage(
        receiverID,
        messageText,
        receiverType
      );
      CometChat.sendMessage(textMessage)
        .then((message) => {
          this.message = '';
          this.messages.push(message);
        })
        .catch((error) => {});
    },
    listenForMessage() {
      const listenerID = this.uid;
      CometChat.addMessageListener(
        listenerID,
        new CometChat.MessageListener({
          onTextMessageReceived: (messageReceipt) => {
            this.user.status = 'online';
            if (this.uid === messageReceipt.sender.uid) {
              this.messages.push(messageReceipt);
              const messageId = messageReceipt.id;
              const receiverId = messageReceipt.receiverId;
              const senderID = messageReceipt.sender.uid;
              const receiverType = 'user';
              CometChat.markAsRead(messageId, receiverId, receiverType, senderID);
            }
          },
          onMessagesDelivered: (messageReceipt) => {
            this.messages.filter((msg) => (msg.deliveredAt = messageReceipt.deliveredAt));
          },
          onMessagesRead: (messageReceipt) => {
            this.messages.filter((msg) => (msg.readAt = messageReceipt.readAt));
          },
        })
      );
    },
    scrollToEnd () {
      const elmnt = this.$refs.container;
      elmnt.scrollTop = elmnt.scrollHeight + 30000;
    },
    toReadableString(time) {
      if (time < 0) time = 0;
      let hrs = ~~((time / 3600) % 24),
        mins = ~~((time % 3600) / 60),
        timeType = hrs > 11 ? 'PM' : 'AM';
      if (hrs > 12) hrs = hrs - 12;
      if (hrs == 0) hrs = 12;
      if (mins < 10) mins = '0' + mins;
      return hrs + ':' + mins + timeType;
    },
    act(){
      this.switch1 = false;
      this.deleteMyFriend(this.uid);
    },
    async viewProfile(){
      await this.getUserLike(this.uid);
      this.$router.push({
        name: 'profileMatch',
      })
    }
  },
  watch: {
    messages() {
      this.scrollToEnd()
    },
    end(){
      let listenerID = 'UNIQUE_LISTENER_ID';

      CometChat.addUserListener(
      listenerID,
      new CometChat.UserListener({
        onUserOnline: onlineUser => {
        },
        onUserOffline: offlineUser => {
        }
      })
    );
    }
  }
};
</script>

<style lang="scss" scoped>
html {
  background-color: #36b93b;
  
}
.it{
  padding:5px;
  display:flex;
  flex-direction: row;
  align-items: center;
}

.sl{
  //background: red;
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: relative;
}
.content-wrapper {
  width: 100%;
}
.content {
  clear: both;
  background: #f9f9ff;
  overflow-y: hidden;
  height: 100vh;
}
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: var(--body-bg);
  //font-family: Helvetica, sans-serif;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.msger {
  height: calc(100% - 50px);
  border: var(--border);
  border-radius: 5px;
  background: var(--msger-bg);
  box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.2);

  @media (min-width: 650px) {
    margin-right:15%;
    margin-left: 15%;
  }
}
.msger-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: var(--border);
  background: #ececff;
  color: #666;
  width: 100% !important;
}
.msger-header-title {
  display: flex;
  align-items: center;

  .txtstatus{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
}
.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  height: 100vh;
  padding-bottom: 30px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg {
  display: flex;
  align-items: flex-end;
}
.msg-list {
  margin: 10px 0;
}
.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background: var(--left-msg-bg);
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.msg-text{
  font-weight: 700;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time {
  font-size: 0.85em;
  display: flex;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin-top: 8px;
  font-weight: 700;
}
.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}
.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: rgb(32, 168, 32);
  
  color: #fff;
  border-bottom-right-radius: 0;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
}
.left-msg .msg-bubble {
  display: inline-block;
  border-radius: 12px;
  background-color: rgb(246, 246, 246);
  padding: 8px 12px;
  align-self: flex-start;
  width: auto;
}
.msger-inputarea {
  padding: 10px;
  border-top: var(--border);
  background: #eee;
  position: fixed;
  bottom: 0;
  width: 100%;
  @media (min-width: 650px) {
    width: 70%;
    box-sizing: border-box !important;
  }
  
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 1em;
}
.msger-input {
  flex: 1;
  width: calc(100% - 50px) !important;
  background: #ddd;
}
.msger-send-btn {
  padding:0;
  margin-left: 5px;
  background: green;
  color: #fff;
  cursor: pointer;
  transition: background 0.23s;
  border-radius: 10px;
  font-weight: bold;
}
.msger-send-btn:hover {
  background: #ddd;
}
.msger-chat {
  background-color: #fcfcfe;
}
.spacer {
  margin-bottom: 150px;
}

.msger__options span {
  margin: 0 5px;
  cursor: pointer;
}
.right-msg .bubble--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}
.left-msg .bubble--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .content {
    margin-left: 0;
  }
  .msger-input {
    width: calc(100% - 105px);
  }
}
:root {
  --body-bg: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  --msger-bg: #fff;
  --border: 2px solid #ddd;
  --left-msg-bg: #ececec;
  --right-msg-bg: #579ffb;
}
</style>
