<template>
  <div class="base-label">
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'BaseLabel',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .base-label {
    font-size: 24px;
    font-weight: 500;
    line-height: 37px;
    text-align: center;
    color: #000;
  }
</style>