<template>
  <SignUpStep
    title="Try 3 Prompts"
    class="signup-bio"
  >
    <Load v-if="stateLoad" />
    <div class="prompts">
      <v-list 
        rounded
        style="background-color: white; margin-bottom: 50px;"
      >
        <v-list-item
          v-for="item in currentPrompts"
          :key="item.id"
          style="background-color:rgb(252, 252, 252); margin-bottom: 3%;"
        >
          <v-list-item-icon
            v-if="item.valid"
            style="cursor: pointer"
            @click="deletePromptModal(item.id)"
          >
            <v-icon :color="'red'">
              mdi-delete-empty-outline
            </v-icon>
          </v-list-item-icon> 

          <v-list-item-content
            style="cursor: pointer"
            @click="openDialog(item)"
          >
            <v-list-item-title
              class="text-wrap"
              v-text="item.title"
            />
          </v-list-item-content>

          <v-list-item-icon
            style="cursor: pointer"
            @click="openDialog(item)"
          >
            <v-icon :color="'grey'">
              fas fa-chevron-right
            </v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider />
      </v-list>
      <v-footer
        dark
        color="rgb(252, 252, 252)"
        padless
        fixed
        class="foot"
      >
        <v-card
          color="rgb(252, 252, 252)"
          width="100%"
          class="d-flex flex-row"
        >
          <v-card-text
            class="d-flex justify-start"
            style="color: black; align-items: center;"
          >
            <div
              class="text--primary"
              style="font-size: 18px;  font-weight: bold;"
            >
              {{ countPrompt }} Prompts
            </div>
          </v-card-text>
          <v-spacer />
          <v-card-text class="d-flex justify-end">
            <v-btn
              class="mx-4 white--text"
              dark
              color="primary"
              @click="saveAllPrompt()"
            >
              Continue
            </v-btn>
          </v-card-text>
        </v-card>
      </v-footer>
      <v-dialog
        v-model="modalPrompt"
        scrollable
        max-width="500px"
      >
        <v-card>
          <v-card-title
            class="text-wrap"
            style="font-size: 18px;"
          >
            {{ dataDialog.titleItem }}
          </v-card-title>
          <v-divider />
          <v-card-text style="margin: 0px; padding: 0px; width: 100%;">
            <BaseTextArea
              v-model="text"
              class="txtarea"
              place-holder=""
              :limit="250"
              :height="'150px'"
              :width="'5%'"
            />
          </v-card-text>
        
          <v-divider />
          <v-card-actions class="justify-end">
            <v-btn
              color=" darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="savePrompt()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="modalDelete"
        width="600"
        style="border-radius: 50px"
      >
        <v-card style="display:flex; flex-direction: column">
          <v-card-title
            class="th"
            style="hyphens: auto; overflow-wrap: break-word;"
          >
            Are you sure you want to remove?
          </v-card-title>
          <v-icon
            right
            large
            color="green"
            style="font-size:40px; align-self: center"
          >
            mdi-delete-empty-outline
          </v-icon>
          <v-card-actions class="d-flex justify-center"> 
            <v-btn
              class="th"
              color="danger"
              text
              @click="closeDeletePromptModal()"
            >
              Cancel
            </v-btn>
            <v-btn
              class="th"
              color="primary"
              text
              @click="deletePrompt()"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
      v-model="modalError"
      width="250px"
      style="border-radius: 50px; overflow-x: hidden !important;"
    >
      <v-card>
        <div style="text-align: center;">
          <v-icon
            large
            color="red"
            style="font-size:80px"
          >
            mdi-alert-circle-outline
          </v-icon>
          <v-card-text>
            {{ modalErrorText }}
          </v-card-text>
        </div>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="black"
            text
            @click="setModal()"
          >
            Ok
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </SignUpStep>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { BaseButton, BaseError, BaseTextArea } from '@/components/Element';
import SignUpStep from '@/components/Layout/SignUpStep';
import { Load } from '@/components/Element';
import { prompts } from '@/data/prompts';

export default {
  name: 'SignupBio',
  components: {
    Load,
    BaseTextArea,
    SignUpStep
  },
  data() {
    return {
      error: false,
      stateLoad: false,
      dialogFeedBack: false,
      dataDialog:{
        idItem: null,
        titleItem: null
      },
      modalPrompt: false,
      modalDelete: false,
      idDeletePrompt: null,
      modalError: false,
      modalErrorText: '',
    };
  },
  computed: {
    ...mapGetters(['bio', 'getPrompt', 'getListPrompt']),
    text: {
      get: vm => vm.getPrompt.text,
      set(value) {
        this.setPromptText(value);
      }
    },
    countPrompt(){
      return this.getListPrompt.length;
    },
    currentPrompts() {
      let newArray = [];
      prompts.forEach((element, index) => {
        const id = this.getListPrompt.findIndex(x => x.id === element.id);
        if(id >= 0){
          newArray.push({
            id: element.id,
            title: element.title,
            valid: true,
          })
        }else{
          newArray.push({
            id: element.id,
            title: element.title,
            valid: false,
          })
        }
      });
      return newArray;
    },
  },
  async created(){
    await this.scrollTop();
  },
  methods: {
    ...mapActions(['addDataPromptUser','sendDataPromptUser']),
    ...mapMutations(['setBio', 'setCurrentStep', 'setPromptText', 'addPromptsToUser',
      'setPromptId',
      'deletePromptToList']),
    async scrollTop(){
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    openDialog(item){
      if(this.countPrompt === 3){
        //alert('3 Prompt Maximum');
        this.modalErrorText = '3 Prompt Maximum';
        this.modalError = true;
        return;
      }
      this.dataDialog = {
        idItem: item.id,
        titleItem: item.title
      }
      this.setPromptText('');
      this.setPromptId(item.id);
      const itemIndex = this.getListPrompt.findIndex(x => x.id === item.id);
      if(itemIndex >= 0){
        const dataOfList = this.getListPrompt[itemIndex];
        this.setPromptText(dataOfList.text);
      }
      this.modalPrompt = true;
    },
    closeDialog(){
      this.dataDialog = {
        idItem: null,
        titleItem: null
      }
      this.setPromptText('');
      this.setPromptId(null);
      this.modalPrompt = false; 
    },
    async savePrompt(){
      if(this.getPrompt.id == null || this.getPrompt.text == ''){
        this.modalErrorText = 'Complete';
        this.modalError = true;
        return;
      }

      try {
        const dataResponse = await this.addDataPromptUser();
        if(dataResponse){
          this.closeDialog();
        }else { 
          this.modalErrorText = 'Error';
          this.modalError = true;
        }
      } catch (error) {
        console.log(error);
        this.modalErrorText = 'Error';
        this.modalError = true;
      }
      
    },
    async deletePromptModal(id){
      this.idDeletePrompt = id;
      this.modalDelete = true;
    },
    async closeDeletePromptModal(id){
      this.idDeletePrompt = null;
      this.modalDelete = false;
    },
    async deletePrompt(){
      if(this.idDeletePrompt == null){
        this.modalErrorText = 'Error';
        this.modalError = true;
        return;
      }
      try {
        this.deletePromptToList(this.idDeletePrompt);
        this.closeDeletePromptModal();
      } catch (error) {
        console.log(error);
        this.modalErrorText = 'Error';
        this.modalError = true;
      }
      
    },
    async saveAllPrompt(){
     if(this.getListPrompt.length < 3){
      //alert('3 Prompt Minimum');
      this.modalErrorText = '3 Prompt Minimum.';
      this.modalError = true;
      return;
     }

     try {
      this.addPromptsToUser(this.getListPrompt);
      this.setCurrentStep(12);
     } catch (error) {
      console.log(error);
      this.modalErrorText = 'Error';
      this.modalError = true;
     }
    },
    closePrompts(){
      this.$router.go(-1);
    },
    async submit() {
      if (!this.bio) {
        this.error = true;
        return;
      }

      this.error = false;
      this.setCurrentStep(12);
    },
    setModal(){
      this.modalError = false;
      this.modalErrorText = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.signup-bio {

  .prompts{
  @media (min-width: 600px) {
    margin-right: 20%;
    margin-left: 20%;
  }

  .foot{
    @media (min-width: 600px) {
    margin-right: 20%;
    margin-left: 20%;
  }
  }


  .txtarea {

    @media (max-width: 500px) {
      margin-bottom: 0;
      textarea {
        height: 100px;
      }
    }
  }
}
  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 454px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: 100%;
      padding: 0 20px;
    }
  }

  &__label {
    margin-top: 50px;
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
    color: #000;
    margin-bottom: 30px;

    @media (max-width: 500px) {
      margin: 30px 0px;
      text-align: center;
      line-height: 28px;
    }
  }

  &__textarea {
    margin-bottom: 10px;

    @media (max-width: 500px) {
      margin-bottom: 0;

      textarea {
        height: 200px;
      }
    }
  }

  &__submit {
    margin-top: 20px;
  }
}
</style>