<template>
  <div
    :class="{'base-option--selected': selected}" 
    class="base-option"
    @touchstart="selected = true"
    @touchend="selected = false"
    @mouseover="selected = true"
    @mouseleave="selected = false"
    @click="select($event)"
  >
    {{ text }}
  </div>
</template>

<script>
  export default {
    name: 'BaseOption',
    props: {
      text: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        selected: false,
        count:0
      };
    },
    created(){
      this.selected = false;
    },
    methods: {
      select() {
        this.$emit('selected', this.text);
        this.selected = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .base-option {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 334px;
    height: 70px;
    margin-bottom: 30px;
    color: #009933;
    border: 2px solid #009933;
    border-radius: 8px;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    cursor: pointer;
    text-align: center;
    padding-top:4px;
    padding-bottom: 3px;
    padding-left: 1px;
    padding-right: 1px;

    @media (max-width: 500px) {
      margin-bottom: 20px;
      font-size: 17px;
      height: 56px;
      width: calc(100% - 40px);
      padding-top:5px;
      padding-bottom: 4px;
      padding-left: 2px;
      padding-right: 2px;
    }

    &--selected {
      background-color: #009933;
      color: #fff;
    }

  }

</style>
