<template>
  <div
    class="base-text-area"
    :style="{ 'padding-left': width, 'padding-right': width }"
  >
    <v-textarea
      v-model="text"
      :style="{ 'height': height }"
      :placeholder="placeHolder" 
    />
    <div
      v-if="limit !== null"
      class="base-text-area__limit"
    >
      {{ text.length }}/{{ limit }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTextArea',
  props: {
    placeHolder: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: null
    },
    height: {
      type: String,
      required: false,
      default: '500px'
    },
    width: {
      type: String,
      required: false,
      default: '0px'
    }
  },
  computed: {
    text: {
      get: vm => vm.value,
      set(newValue) {
        this.$emit('input', newValue);
      }
    }
  },
  watch: {
    text(val) {
      if(val && val.length){
        if (val.length > this.limit) {
          this.text = val.substr(0, this.limit);
        }
      }
      
    }
  }
};
</script>

<style lang="scss" scoped>
.base-text-area {
  width: 100%;
  @media (max-width: 500px) {
    width: 100%;
  }
  textarea {
    border-radius: 10px;
    width: 100%;
    height: 320px;
    padding: 10px;
    border: 1.5px solid #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  &__limit {
    text-align: right;
    margin-top: 7px;
  }
}
</style>
