<template>
  <SignUpStep
    title="Photos"
    class="signup-photos"
  >
    <div class="signup-photos__form">
      <p class="signup-photos__label">
        Add 3-6 photos.
      </p>
      <BaseError
        v-if="error !== null"
        :message="error"
      />
      
      <vue-dropzone
        id="dropzone"
        ref="myVueDropzone"
        class="drop"
        :options="dropzoneOptions"
        @vdropzone-file-added="sendingFile"
        @vdropzone-removed-file="removeFile"
      />
      <BaseButton
        class="signup-photos__submit"
        text="Next"
        @my-event="submit()"
      />
    </div>
  </SignUpStep>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { BaseButton, BaseError } from '@/components/Element';
import SignUpStep from '@/components/Layout/SignUpStep';

export default {
  name: 'SignupPhotos',
  components: {
    BaseButton,
    BaseError,
    SignUpStep,
  },
  data() {
    return {
      error: null,
      files: [],
      images: [],
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'http://localhost/',
        thumbnailWidth: 120,
        thumbnailHeight: 120,
        addRemoveLinks: true,
        thumbnailMethod:'contain',
        dictDefaultMessage: "<i class='fa fa-cloud-upload'></i>    Add photos, or drag and drop."
      },

    };
  },
  computed: {
    ...mapGetters({
      photos: 'photos'
    })
  },
  watch: {
    photos() {
      if (this.photos.length >= 3) {
        this.error = null;
      }
    }
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setCurrentStep', 'addPhoto']),
    async submit() {
      if(this.images.length >= 3){
        this.addPhoto(this.images);
        this.setCurrentStep(13);
      }else{
        this.error = 'You need to add at least 3 photos!';
        return;
      }
    },
    uploadImageSuccess(formData, index, fileList) {
      this.images = []
      this.images = fileList
    },
    beforeRemove (index, done, fileList) {
      var r = confirm('remove image')
      if (r == true) {
        this.images = []
        done()
        this.images = fileList
      }
    },
    editImage(formData, index, fileList) {
      this.images = []
      this.images = fileList
    },
    markIsPrimary(index, fileList){
    },
    limitExceeded(amount){
    },
    sendingFile(file) {
      this.images.push(file);
      setTimeout(() => {
        var elems = document.querySelectorAll('.dz-progress')
        var index = 0
        var length = elems.length
        for (; index < length; index++) {
          (elems[index]).style.background ='#009933';
        }
      },3000);

      setTimeout(() => {
        var elems = document.querySelectorAll('.dz-progress')
        var index = 0
        var length = elems.length
        for (; index < length; index++) {
          (elems[index]).style.display ='none';
        }
      },5000);
      
    },
    removeFile(file, error, xhr){
      if(error){
        return;
      }
      if(!file){
        return;
      }
      const rest = this.images.filter(x => x.upload.uuid !== file.upload.uuid);
      this.images = [];
      this.images = rest;
    },
  }
};
</script>

<style lang="scss" scoped>
  
  @import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
.signup-photos {

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: green;
  margin-bottom: 20px;
}

.drop{
  margin-bottom: 25px;
  width: 100%;

  @media (min-width: 600px) {
    width: 500px;
  }

  .dz-progress {
  /* progress bar covers file name */
    display: none !important;
    background: red;
  }

  .dz-image img{
    width: 130px;
    height:130px;
  }

  .dz-thumbnail img { width: 100%; }
}

 #dropzone .dz-preview .dz-progress {
  /* progress bar covers file name */
    display: none !important;
    background: red;
  }

  .dropzone .dz-preview .dz-progress {
  opacity: .50;
  background: red;
  }


  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }

  &__label {
    margin-top: 96px;
    font-size: 22px;
    font-weight: 300;
    line-height: 27px;
    margin-bottom: 30px;

    @media (max-width: 500px) {
      margin-top: 34px;
    }
  }

  &__boxes {
    display: grid;
    grid-template-columns: repeat(3, 229px);
    grid-auto-rows: 229px;
    grid-gap: 22px;
    margin-bottom: 130px;

    @media (max-width: 760px) {
      grid-template-columns: repeat(2, 229px);
    }

    @media (max-width: 500px) {
      grid-template-columns: repeat(2, 150px);
      grid-auto-rows: 150px;
      margin-bottom: 20px;
    }
  }
}
</style>
