<template>
  <SignUpStep
    title="Finish Account"
    class="signup-finish-account"
    :back="true"
    :step="10"
  >
    <div class="signup-finish-account__form">
      <BaseError
        v-if="error !== null"
        :message="error"
      />
      <BaseErrorEmail
        v-if="erroremail !== null"
        :message="erroremail"
      />
      <BaseLabel
        label="Email Address"
        class="signup-finish-account__label"
      />
      <BaseInput
        v-model="emailAddressModel"
        :value="emailAddressModel"
        placeholder="Enter your email address"
        class="signup-finish-account__input"
        :type="'email'"
      />
      <BaseLabel
        label="Create a Password"
        class="signup-finish-account__label"
      />
      <BaseInput
        v-model="password"
        :value="password"
        placeholder="Enter Password "
        :type="'password'"
        class="signup-finish-account__input"
      />
      <BaseInfo
        :show-icon="false"
        message="8 character minimum"
      />
      <button
        class="base-button signup-finish-account__submit"
        @click="submit()"
      >
        Create Account
      </button>
    </div>
    <Loader v-if="loadStatus" />
  </SignUpStep>
</template>

<script>
import { CometChat } from '@cometchat-pro/chat';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import {
  //BaseButton,
  BaseInfo,
  BaseInput,
  BaseLabel,
  BaseError,
  BaseErrorEmail,
  Loader
} from '@/components/Element';
import SignUpStep from '@/components/Layout/SignUpStep';

export default {
  name: 'FinishAccount',
  components: {
    BaseError,
    BaseErrorEmail,
    BaseInfo,
    BaseInput,
    BaseLabel,
    SignUpStep,
    Loader
  },
  data() {
    return {
      error: null,
      erroremail: null,
      password: '',
      loadStatus:false
    };
  },
  computed: {
    ...mapGetters(['email', 'user','loadingStatus', 'photos']),
    emailAddressModel: {
      get: vm => vm.email,
      set(value) {
        this.setEmail(value);
      }
    }
  },
  methods: {
    ...mapActions(['register', 'deleteEmailUser', 'sendRegisterRequest']),
    ...mapMutations(['setCurrentStep', 'setEmail', 'setUserPassword']), 
    async submit() {
      if (!this.email) {
        this.error = 'Email Address is required';
        return;
      }

      if (!/.+@.+\..+/.test(this.email)) {
        this.error = 'Email Address is not valid';
        return;
      }

      if (!this.password || this.password.length < 8) {
        this.error = 'Password must be at least 8 characters long';
        return;
      }
      this.setUserPassword(this.password);
      this.loadStatus = true;
      const res = await this.sendRegisterRequest(); 

      if(res.valid === true){
        let authKey = this.user.authKey;
        var username = this.user.id;
        var name = this.user.firstName + ' ' + this.user.lastName;
        var user = new CometChat.User(username);
        user.setName(name);
        //user.setAvatar(`https://oss.tryfilters.com/${this.user.photos[0]}`);
        CometChat.createUser(user, authKey).then(
          user => {
            CometChat.login(username, authKey).then(
              data => {
                this.loadStatus = false;
                this.$router.push({
                  name: 'viewProfileNewUsers'
                });
                this.setCurrentStep(0);
              }, 
              error => {
                this.loadStatus = false;
                alert(
                  'Whoops. Something went wrong.'
                );
                this.error = ('Login failed. Try in login page.');
              }
            ).catch(err => {
              this.loadStatus = false;
              this.error = ('Login failed. Try in login page.');
            })
          }, async (error) => {
            const response = await this.deleteEmailUser(this.user.id);
            if(response){
              this.loadStatus = false;
              this.error = ('Register failed. Try again');
            }else{
              this.loadStatus = false;
              this.error = ('Register failed. Try again with other email');
            }
          }
        ).catch( async (error) => {
          alert(
            "Whoops. Something went wrong. This commonly happens when you enter a username that doesn't exist. Check the console for more information"
          );
          const response = await this.deleteEmailUser(this.user.id);
          if(response){
            this.loadStatus = false;
            this.error = ('Register failed. Try again');
          }else{
            this.loadStatus = false;
            this.error = ('Register failed. Try again with other email');
          }
        })
      }else{
        this.loadStatus = false;
        console.log(res?.message);
        this.error = res.message || 'Register Failed, Try again';
        if(res && res?.message === 'Password is weak'){
          this.error = 'Password must be at least 8 characters'
        }
        if(res && res?.message === 'Email already exist'){
          this.erroremail = 'Email already exists.  Try logging in'
          this.error = null;
        }
        return
      }
    }
  }
};
</script>

<style lang="scss" scoped>

.base-button {
  padding: 12px 28px;
  width: 450px;
  height: 62px;
  background: #009933;
  border: 1.5px solid #009933;
  border-radius: 8px;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  color: #fff;
  outline-width: 0;
  cursor: pointer;
  margin-bottom: 20px;

  @media (max-width: 500px) {
    width: 100%;
  }

  &:hover {
    opacity: 0.8;
  }
}
.signup-finish-account {
  .hed{
      background: red; 
      display: flex; 
      flex-direction: row;
      justify-content: center;

      @media (max-width: 600px) {
        flex-direction: column;
      }

      .btnHead{
        margin-top: 20px; 
        margin-left: 0px;
        margin-right: 6 0px;
        
        @media (max-width: 600px) {
          margin-top: 20px; 
          margin-left: 20px;
        }
      }
    }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 50px;
    width: 450px;
    margin: 0 auto;

    @media (max-width: 500px) {
      width: calc(100% - 40px);
    }
  }

  &__label {
    margin-top: 30px;

    &:not(:first-child) {
      margin-top: 60px;
    }

    @media (max-width: 500px) {
      margin: 0;
      font-size: 24px;
      text-align: left;
    }
  }

  &__submit {
    margin-top: 40px;
  }

  .info-message {
    margin-left: 25px;
  }
}
</style>
